var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Vue } from "vue-property-decorator";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Owner/RedemptionStaff");
        });
    }
    outletList(p) {
        if (!p.Outlets.length)
            return "無";
        return p.Outlets.map(o => o.Name).join("、");
    }
    deleteStaff(p) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data || !(yield this.confirm(p.Name, "確定刪除核銷人員", "warning")))
                return;
            try {
                yield post("/api/Owner/RemoveRedemptionStaff", { Id: p.Id });
                this.data.splice(this.data.indexOf(p), 1);
            }
            catch (e) {
                if (e instanceof Error)
                    this.alert(e.message, "刪除失敗", "error");
            }
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
