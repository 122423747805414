
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import clone from "clone";

	import ButtonSelect from "@/components/ButtonSelect.vue";
	import DetailOutlet from "./components/DetailOutlet.vue";

	import type { DistributerEditModel, DistributerOutletEditModel, DistributerOutletIndex, DistributerOutletModel } from "@/models/owner/Distributer";

	@Component({ components: { DetailOutlet, ButtonSelect } })
	export default class Page extends Vue {
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		@Prop() public id!: string;

		protected data: DistributerOutletIndex | null = null;
		protected backup!: DistributerOutletIndex;
		protected editMode: boolean = false;
		protected tab: number = 0;
		protected filter: number = 0;

		get title(): string {
			if(!this.data) return "";
			return `${this.data.Name}（${this.data.Username}）`;
		}

		get filtered(): DistributerOutletModel[] {
			if(!this.data) return [];
			if(this.filter == 0) return this.data.Outlets;
			const codename = this.filter == 1 ? "eventgiveaway" : "pointgiveaway";
			return this.data.Outlets.filter(o => o.CodeName == codename);
		}

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = await post<DistributerOutletIndex>("/api/Distributer/Detail", { Id: this.id });
		}

		protected startEdit(): void {
			this.backup = clone(this.data!);
			this.editMode = true;
		}

		protected cancelEdit(): void {
			this.data = this.backup;
			this.editMode = false;
		}

		protected async send(): Promise<void> {
			if(!this.data) return;

			// 整理模型差異
			const model: DistributerEditModel = {
				Id: this.id,
				Settings: this.data.Settings,
				Outlets: [],
			};
			const oldOutlets = this.backup.Outlets;
			const newOutlets = this.data.Outlets;
			for(let i = 0; i < this.data.Outlets.length; i++) {
				let changed = false;
				let outlet: DistributerOutletEditModel = {
					Id: newOutlets[i].Outlet.Id!,
					PromotionTypeId: newOutlets[i].PromotionTypeId,
					Promotions: [],
				};
				if(newOutlets[i].EnableByDefault !== oldOutlets[i].EnableByDefault) {
					changed = true;
					outlet.EnableByDefault = newOutlets[i].EnableByDefault;
				}
				for(let j = 0; j < newOutlets[i].Promotions.length; j++) {
					const promo = newOutlets[i].Promotions[j];
					if(promo.IsActive !== oldOutlets[i].Promotions[j].IsActive) {
						changed = true;
						outlet.Promotions.push({ Id: promo.Promotion.Id, IsActive: promo.IsActive });
					}
				}
				if(changed) model.Outlets.push(outlet);
			}

			await post<boolean>("/api/Distributer/Edit", { ...model });
			this.editMode = false;
		}
	}
