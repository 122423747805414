
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { ItemSupplyStatus } from "@/models/Item";
	import { DeliveryMethodCode, ItemPurchaseType } from "@/models/owner/Mall";
	import BasicInfoCard from "./components/BasicInfoCard.vue";
	import EditPriceCard from "./components/EditPriceCard.vue";
	import type { MallItemEditModel } from "@/models/owner/Mall";


	type Data = MallItemEditModel;

	@Component({ components: { BasicInfoCard, EditPriceCard } })
	export default class Page extends Vue {
		@Inject() public alert!: AlertMethod;
		@Inject() public confirm!: ConfirmMethod;

		@Prop() public id!: string; // outletId

		protected data: Data | null = null;

		mounted(): void {
			this.load();
		}

		private async load(): Promise<void> {
			this.data = null;
			this.data = await post<Data>("/api/Mall/GetItemEditModel", { Id: this.id });
			this.data.SupplyStatus = ItemSupplyStatus.InStock;
			this.data.Settings.DeliveryMethods = [DeliveryMethodCode.Outlet];
		}

		protected async send(): Promise<void> {
			if(!this.data) return;
			if(!this.data.Settings.DeliveryMethods.includes(DeliveryMethodCode.Post)) {
				this.data.ItemPurchase = this.data.ItemPurchase.filter(p => p.Type != ItemPurchaseType.Postage);
			}
			await post<boolean>("/api/Mall/EditItem", { ...this.data });
			this.$router.back();
		}
	}
