

	import { Component, Inject, Watch } from "vue-property-decorator";
	import Modal from "@/components/modal";

	import type { MallItemModel } from "@/models/owner/Mall";

	type Data = MallItemModel;

	@Component
	export default class StockModal extends Modal {
		@Inject() public readonly alert!: AlertMethod;

		public model: Data | null = null;
		protected stock: number = 0

		@Watch("model") onModel(v: Data): void {
			if(v) this.stock = v.Item.Stock || 0;
		}

		public async submit(): Promise<void> {
			if(!this.model) return;
			const model = this.model;
			const old = model.Item.Stock;
			model.Item.Stock = this.stock;
			try {
				await post('/api/Mall/EditItemStock', {
					Id: model.Item.Id,
					Value: this.stock,
				});
				this.$emit("edit");
			} catch(e) {
				model.Item.Stock = old;
				let msg = e instanceof Error ? e.message : '儲存失敗，請稍後再試';
				await this.alert(msg, '', 'warning');
			}
			this.model = null;
		}
	}
