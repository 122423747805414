import type { IdDateQuery } from "../Base";
import type { ContactModel, OutletModel, OutletModelBase } from "@common/models/merchant";
import type { PointGiveawayPointBaseModel } from "../Owner";
import type { ItemDataModel, ItemEditModel, ItemModel } from "../Item";

export interface MallIndexModel {
	Outlets: OutletModel[];
	Settings: MallDistributerSettings;
}

export interface MallItemIndexModel {
	Items: MallItemModel[];
}

export interface MallItemModel {
	Item: ItemModel;
	Settings: MallItemSettingModel;
	Purchases: ItemPurchaseModel[];
	Link: string;
}

export interface MallItemSettingModel {
	Remark: string;
	DeliveryMethods: DeliveryMethodCode[];
}

export enum DeliveryMethodCode {
	Outlet = 1,
	Post = 2,
}

export interface ItemPurchaseModel {
	ItemId: string;
	ByPayType: number | null;
	Price: number;
	Type: ItemPurchaseType;
	ByItem: ItemModel;
	Name: string;
}

export enum ItemPurchaseType {
	Main = 0,
	Postage = 1,
}

export interface ItemPurchaseEditModel {
	ByItemId?: string;
	PaymentTypeId?: string;
	Type: ItemPurchaseType;
	Price: number;
}

export interface PostageEditModel {
	Id: string;
	Points: PointGiveawayPointBaseModel[];
	Settings: ItemPurchaseEditModel[];
}

export interface MallItemEditModel extends ItemEditModel {
	Outlet: OutletModelBase;
	Points: PointGiveawayPointBaseModel[];
	Settings: MallItemSettingModel;
	ItemPurchase: ItemPurchaseEditModel[];
}

export interface TransactionBaseModel {
	Id: string;
	Clerk: string;
	UserId: string;
	Serial: number;
	SerialString: string;
	Status: TransactionStatusCode;
	PaidStatus: PaidStatusCode;
	ShipStatus: ShipStatusCode;
	CreateTime: Date;
	DueTime: Date | null;
	UpdateTime: Date;
	MerchantId: string;
	Receiver: string;
	ReceiverPhone: string;
	Comment: string;
	Remark: string;
	Data: string;
}

export interface TransactionShipmentModel {
	DueTime: string | null;
	Status: ShipStatusCode;
	Data: string;
	Contact: ContactModel;
}

export interface TransactionModel extends TransactionBaseModel {
	User: UserModel;
	TransactionPurchase: TransactionPurchaseModel[];
	TransactionShipment: TransactionShipmentModel[];
	TransactionLog: TransactionLogModel[];
}

export interface UserModel {
	Id: string;
	AccountId: string;
	SerialString: string;
	Username: string;
	Contact: ContactModel;
	UsernameMask: string;
}

export interface TransactionPurchaseModel {
	ItemDataId: string;
	Amount: number;
	ShipmentStatus: ShipStatusCode;
	ItemData: ItemDataModel;
	Total: number;
	Summary: string;
}

export enum TransactionStatusCode {
	Submitted = 1,
	Received = 2,
	Completed = 3,
	Cancelled = 4,
}

export enum PaidStatusCode {
	NotApplicable = 0,
	Unpaid = 1,
	Paid = 2,
	Refund = 3,
}

export enum ShipStatusCode {
	NotApplicable = 0,
	Preparing = 1,
	Shipping = 2,
	PickupReady = 3,
	Pickedup = 4,
	Confirmed = 5,
	Returned = 6,
}

export interface LeapaMallIndexQuery extends IdDateQuery {
	DeliveryMethod: DeliveryMethodCode | null;
	AccountId: string;
	Serial: string;
}

export interface TransactionLogModel {
	Change: TransactionLogData;
	CreateTime: string;
}

export interface TransactionLogData {
	Status: TransactionStatusCode | null;
	PaidStatus: PaidStatusCode | null;
	ShipStatus: ShipStatusCode | null;
	PaymentDetail: { [key: string]: PaidStatusCode; };
	Remark: string;
}

export interface MallDistributerSettings {
	CanManageItem: boolean;
	CanManageOrder: boolean;
	CanWriteOff: boolean;
}
