export function holesBefore(prizes, n) {
    let result = 0;
    for (let i = 0; i < n; i++) {
        result += prizes[i].Amount;
    }
    return result;
}
export function serial(at, amount) {
    const start = at + 1;
    if (Number.isNaN(start) || !amount)
        return "";
    const end = start + amount - 1;
    return start < end ? start + "-" + end : start.toString();
}
export function remain(settings) {
    if (!settings.HoleCount)
        return 0;
    let total = 0;
    for (const p of settings.Prizes) {
        if (p.Amount)
            total += p.Amount;
    }
    return settings.HoleCount - total;
}
const A_CODE = 65;
const ROW_COUNT = 4;
export function formatHole(n) {
    n -= 1;
    return String.fromCharCode(A_CODE + n % ROW_COUNT) + (Math.floor(n / ROW_COUNT) + 1);
}
