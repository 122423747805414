
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { ItemSupplyStatus } from "@/models/Item";

	@Component
	export default class SupplyStatus extends Vue {
		@Prop() public data!: ItemSupplyStatus;

		protected get ItemSupplyStatus(): typeof ItemSupplyStatus {
			return ItemSupplyStatus;
		}
	}
