var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { EventGiveawayType } from "@/models/Owner";
import LimitNumber from "../../components/LimitNumber.vue";
let Settings = class Settings extends Vue {
    constructor() {
        super(...arguments);
        this.random = null;
    }
    onTypeChange() {
        if (this.data.Type != EventGiveawayType.Distributer) {
            this.data.DistributerActivation = null;
        }
    }
    onGoalChange() {
        if (this.useDistributer) {
            this.data.DistributerActivation = this.goal;
        }
    }
    onRandomChange() {
        if (this.data.RandomActivation) {
            post("/api/Owner/TestRandomActivation", Object.assign({}, this.data.RandomActivation))
                .then(v => this.data.RandomActivations = v);
        }
        else {
            this.data.RandomActivations = [];
        }
    }
    get EventGiveawayType() {
        return EventGiveawayType;
    }
    get totalAmount() {
        return this.fixedAmount + this.randomAmount;
    }
    get totalCount() {
        return this.fixedCount + this.randomCount;
    }
    get match() {
        return this.useDistributer || this.totalCount == this.goal;
    }
    get fixedAmount() {
        if (!this.data.FixedActivations)
            return 0;
        let amount = 0;
        for (const a of this.data.FixedActivations) {
            amount += a.Amount;
        }
        return amount;
    }
    get fixedCount() {
        if (!this.data.FixedActivations)
            return 0;
        let count = 0;
        for (const a of this.data.FixedActivations) {
            count += a.Count * a.Amount;
        }
        return count;
    }
    get randomAmount() {
        var _a, _b;
        return (_b = (_a = this.data.RandomActivations) === null || _a === void 0 ? void 0 : _a.reduce((x, a) => x + a.Amount, 0)) !== null && _b !== void 0 ? _b : 0;
    }
    get randomCount() {
        var _a, _b;
        return (_b = (_a = this.data.RandomActivations) === null || _a === void 0 ? void 0 : _a.reduce((x, a) => x + a.Amount * a.Count, 0)) !== null && _b !== void 0 ? _b : 0;
    }
    get hasFixed() {
        return this.data.FixedActivations != null;
    }
    set hasFixed(v) {
        this.data.FixedActivations = v ? [{
                Amount: 0,
                Count: 0,
            }] : null;
    }
    get hasRandom() {
        return this.data.RandomActivation != null;
    }
    set hasRandom(v) {
        this.data.RandomActivation = v ? {
            TotalCount: Math.max(this.goal - this.fixedCount, 0),
            MinCount: 0,
            MaxCount: 0,
        } : null;
    }
    get useDistributer() {
        return this.data.DistributerActivation !== null;
    }
    set useDistributer(v) {
        if (v) {
            this.data.FixedActivations = null;
            this.data.RandomActivation = null;
            this.data.DistributerActivation = this.goal;
            this.data.DisplayCount = true; // 必顯示
        }
        else {
            this.data.DistributerActivation = null;
        }
    }
    addFixed() {
        if (!this.data.FixedActivations)
            return;
        this.data.FixedActivations.push({
            Amount: 0,
            Count: 0,
        });
    }
    removeFixed() {
        var _a;
        (_a = this.data.FixedActivations) === null || _a === void 0 ? void 0 : _a.pop();
    }
};
__decorate([
    Inject()
], Settings.prototype, "confirm", void 0);
__decorate([
    Inject()
], Settings.prototype, "alert", void 0);
__decorate([
    Prop()
], Settings.prototype, "data", void 0);
__decorate([
    Prop()
], Settings.prototype, "goal", void 0);
__decorate([
    Prop()
], Settings.prototype, "label", void 0);
__decorate([
    Prop()
], Settings.prototype, "unit", void 0);
__decorate([
    Watch("data.Type")
], Settings.prototype, "onTypeChange", null);
__decorate([
    Watch("goal")
], Settings.prototype, "onGoalChange", null);
__decorate([
    Watch("data.RandomActivation", { deep: true })
], Settings.prototype, "onRandomChange", null);
Settings = __decorate([
    Component({ components: { LimitNumber } })
], Settings);
export default Settings;
