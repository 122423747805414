
	import { Component, Prop, Vue } from "vue-property-decorator";

	import type { DeliveryMethodCode, MallItemEditModel } from "@/models/owner/Mall";

	@Component({ components: {} })
	export default class BasicInfoCard extends Vue {
		@Prop() public data!: MallItemEditModel;

		protected toggleMethod(type: DeliveryMethodCode): void {
			const methods = this.data.Settings.DeliveryMethods;
			const i = methods.indexOf(type);
			if(i >= 0) methods.splice(i, 1);
			else methods.push(type);
		}
	}
