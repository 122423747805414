var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { DeliveryMethodCode } from "@/models/owner/Mall";
import { deliveryMethodString, formatOption } from "./utils";
let MallItemSummary = class MallItemSummary extends Vue {
    get method() {
        return deliveryMethodString(this.data.Settings.DeliveryMethods);
    }
    get canPost() {
        return this.data.Settings.DeliveryMethods.includes(DeliveryMethodCode.Post);
    }
    list(type) {
        return this.data.Purchases.filter(p => p.Type == type).map(formatOption).join(" / ");
    }
};
__decorate([
    Prop()
], MallItemSummary.prototype, "data", void 0);
MallItemSummary = __decorate([
    Component
], MallItemSummary);
export default MallItemSummary;
