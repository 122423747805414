
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { DeliveryMethodCode } from "@/models/owner/Mall";

	import { deliveryMethodString, formatOption } from "./utils";

	import type { MallItemModel, ItemPurchaseType } from "@/models/owner/Mall";

	@Component
	export default class MallItemSummary extends Vue {
		@Prop() public data!: MallItemModel;

		public get method(): string {
			return deliveryMethodString(this.data.Settings.DeliveryMethods);
		}

		public get canPost(): boolean {
			return this.data.Settings.DeliveryMethods.includes(DeliveryMethodCode.Post);
		}

		public list(type: ItemPurchaseType): string {
			return this.data.Purchases.filter(p => p.Type == type).map(formatOption).join(" / ");
		}
	}
