import { PromotionStatus } from "@/models/Owner";
export function statusText(status, isActive) {
    if (status == PromotionStatus.Expire)
        return "已結束";
    if (status == PromotionStatus.Unstarted)
        return "未開始";
    if (isActive !== undefined)
        return isActive ? "進行中" : "已暫停";
    if (status == PromotionStatus.Active)
        return "進行中";
    if (status == PromotionStatus.Inactive)
        "已暫停";
    return "";
}
export function statusClass(status, isActive) {
    if (status == PromotionStatus.Expire)
        return "text-danger";
    if (status == PromotionStatus.Unstarted)
        return "text-warning";
    if (isActive !== undefined)
        return isActive ? "text-success" : "text-warning";
    if (status == PromotionStatus.Active)
        return "text-success";
    if (status == PromotionStatus.Inactive)
        "text-warning";
    return "";
}
