var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { MINI_CLASS } from "@common/components/button";
import ButtonSelect from "@/components/ButtonSelect.vue";
import { ShipStatusCode, DeliveryMethodCode, TransactionStatusCode } from '@/models/owner/Mall';
import ExcelModal from "../modal/ExcelModal.vue";
import OrderCard from "./components/OrderCard.vue";
import OrderRemarkModal from "./modal/OrderRemarkModal.vue";
import ShipModal from "./modal/ShipModal.vue";
import CancelModal from "./modal/CancelModal.vue";
const EXCEL_TIMEOUT = 60000;
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.MINI_CLASS = MINI_CLASS;
        this.data = null;
        this.filter = 0;
        this.query = {};
        this.hasReceived = true;
        this.hasComplete = true;
        this.hasCancel = true;
        this.hasOutlet = true;
        this.hasPost = true;
    }
    get status() {
        const result = [];
        if (this.hasReceived)
            result.push(TransactionStatusCode.Received);
        if (this.hasComplete)
            result.push(TransactionStatusCode.Completed);
        if (this.hasCancel)
            result.push(TransactionStatusCode.Cancelled);
        return result;
    }
    get method() {
        const result = [];
        if (this.hasOutlet)
            result.push(DeliveryMethodCode.Outlet);
        if (this.hasPost)
            result.push(DeliveryMethodCode.Post);
        return result;
    }
    get filtered() {
        if (!this.data)
            return [];
        if (this.filter == 1)
            return this.data.filter(o => o.ShipStatus == ShipStatusCode.Preparing);
        if (this.filter == 2)
            return this.data.filter(o => o.ShipStatus == ShipStatusCode.PickupReady);
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (this.filter == 3)
            return this.data.filter(o => o.ShipStatus == ShipStatusCode.Shipping);
        return this.data;
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.query.Id = this.id;
            this.data = yield post("/api/Mall/Orders", Object.assign({}, this.query));
        });
    }
    editRemark(o) {
        this.$refs.remark.model = o;
        this.$refs.remark.show();
    }
    ship(o) {
        this.$refs.ship.model = o;
        this.$refs.ship.show();
    }
    cancel(o) {
        this.$refs.cancel.model = o;
        this.$refs.cancel.show();
    }
    excel() {
        this.$refs.excel.show("匯出檔案", email => post('api/Mall/Excel', {
            Id: this.id,
            Email: email,
            Status: this.status,
            Method: this.method,
        }, EXCEL_TIMEOUT));
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Prop()
], Page.prototype, "id", void 0);
Page = __decorate([
    Component({ components: { OrderCard, ButtonSelect, ExcelModal, OrderRemarkModal, ShipModal, CancelModal } })
], Page);
export default Page;
