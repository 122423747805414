var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { statusText, statusClass } from "../../components/StatusString";
let StatusText = class StatusText extends Vue {
    get statusText() {
        return statusText(this.data.Status, this.data.IsActive);
    }
    get statusClass() {
        return statusClass(this.data.Status, this.data.IsActive);
    }
};
__decorate([
    Prop({ default: "span" })
], StatusText.prototype, "as", void 0);
__decorate([
    Prop()
], StatusText.prototype, "data", void 0);
StatusText = __decorate([
    Component
], StatusText);
export default StatusText;
