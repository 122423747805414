
	import { Component, Inject, Vue } from "vue-property-decorator";
	import DistributerItem from "./components/DistributerItem.vue";

	import type { DistributerModel } from "@/models/owner/Distributer";

	@Component({ components: { DistributerItem } })
	export default class Page extends Vue {
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		protected selected: string = "";

		protected data: DistributerModel[] | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = (await post<DistributerModel[]>("/api/Distributer")).filter(d => d.IsActive);
		}

		protected async send(): Promise<void> {
			if(!await this.confirm("刪除子帳號後，該子帳號將無相關權限", "確認刪除子帳號", "danger")) return;
			await post<boolean>("/api/Distributer/Remove", { Id: this.selected });
			await this.alert("刪除成功", undefined, "success");
			this.$router.back();
		}
	}
