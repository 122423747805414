
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { MINI_CLASS } from "@common/components/button";
	import ButtonSelect from "@/components/ButtonSelect.vue";
	import { ShipStatusCode } from "@/models/owner/Mall";
	import OrderCard from "./components/OrderCard.vue";
	import OrderRemarkModal from "./modal/OrderRemarkModal.vue";
	import ShipModal from "./modal/ShipModal.vue";
	import CancelModal from "./modal/CancelModal.vue";

	import type { TransactionModel, LeapaMallIndexQuery } from "@/models/owner/Mall";

	type Data = TransactionModel;

	@Component({ components: { OrderCard, ButtonSelect, OrderRemarkModal, ShipModal, CancelModal } })
	export default class Page extends Vue {
		$refs!: {
			remark: OrderRemarkModal;
			ship: ShipModal;
			cancel: CancelModal;
		}

		protected readonly MINI_CLASS = MINI_CLASS;

		@Inject() public alert!: AlertMethod;

		@Prop() public id!: string;

		protected data: Data[] | null = null;
		protected filter: number = 0;
		protected query: LeapaMallIndexQuery = {} as LeapaMallIndexQuery;

		get filtered(): Data[] {
			if(!this.data) return [];
			if(this.filter == 1) return this.data.filter(o => o.ShipStatus == ShipStatusCode.Preparing);
			if(this.filter == 2) return this.data.filter(o => o.ShipStatus == ShipStatusCode.PickupReady);
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			if(this.filter == 3) return this.data.filter(o => o.ShipStatus == ShipStatusCode.Shipping);
			return this.data;
		}

		mounted(): void {
			this.load();
		}

		private async load(): Promise<void> {
			this.data = null;
			this.query.Id = this.id;
			this.data = await post<Data[]>("/api/Mall/Orders", { ...this.query });
		}

		protected editRemark(o: Data): void {
			this.$refs.remark.model = o;
			this.$refs.remark.show();
		}

		protected ship(o: Data): void {
			this.$refs.ship.model = o;
			this.$refs.ship.show();
		}

		protected cancel(o: Data): void {
			this.$refs.cancel.model = o;
			this.$refs.cancel.show();
		}
	}
