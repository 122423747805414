var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { holesBefore, serial } from "./util";
let PrizeList = class PrizeList extends Vue {
    get total() {
        return this.data.Settings.Prizes.reduce((x, v) => x + v.Amount, 0);
    }
    get noPrizeCount() {
        return this.data.Settings.HoleCount - this.total;
    }
    serial(n) {
        if (n === undefined)
            return serial(this.total, this.noPrizeCount);
        const at = holesBefore(this.data.Settings.Prizes, n);
        return serial(at, this.data.Settings.Prizes[n].Amount);
    }
    remain(n) {
        const amount = n === undefined ? this.noPrizeCount : this.data.Settings.Prizes[n].Amount;
        const claimed = this.data.Records.filter(r => r.PrizeId == n && r.ReceiveTime != null).length;
        return amount - claimed;
    }
};
__decorate([
    Prop()
], PrizeList.prototype, "data", void 0);
PrizeList = __decorate([
    Component
], PrizeList);
export default PrizeList;
