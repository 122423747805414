var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { hasTime } from "@common/date";
let StartEnd = class StartEnd extends Vue {
    get hasTime() {
        if (this.noMin)
            return false;
        return hasTime(this.data.StartTime, this.data.EndTime);
    }
    get start() {
        return this.hasTime ? this.data.StartTime.minute() : this.data.StartTime.date();
    }
    get end() {
        if (!this.data.EndTime)
            return "至今";
        return this.hasTime ? this.data.EndTime.minute() : this.data.EndTime.date();
    }
    remain(date) {
        const diff = Math.max(0, date.getTime() - new Date().getTime());
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return Math.ceil(diff / 86400000);
    }
};
__decorate([
    Prop()
], StartEnd.prototype, "data", void 0);
__decorate([
    Prop(Boolean)
], StartEnd.prototype, "noMin", void 0);
__decorate([
    Prop(Boolean)
], StartEnd.prototype, "showRemain", void 0);
StartEnd = __decorate([
    Component
], StartEnd);
export default StartEnd;
