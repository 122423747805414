var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { EventGiveawayType } from "@/models/Owner";
let Settings = class Settings extends Vue {
    get isDisabled() {
        if (this.data.MixedDistribution) {
            this.data.DisplayCount = false;
            return true;
        }
        if (this.data.Type == EventGiveawayType.Link || this.data.DistributerActivation !== null) {
            this.data.DisplayCount = true;
            return true;
        }
        return false;
    }
};
__decorate([
    Prop()
], Settings.prototype, "data", void 0);
__decorate([
    Prop()
], Settings.prototype, "unit", void 0);
Settings = __decorate([
    Component
], Settings);
export default Settings;
