var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { ItemPurchaseType } from "@/models/owner/Mall";
import PointInput from "./components/PointInput.vue";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.hasPoint = false;
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Mall/GetPostageEditModel", { Id: this.id });
            this.hasPoint = this.data.Points.length > 0;
            if (this.data.Settings.length == 0)
                this.addNew();
        });
    }
    remove(i) {
        if (!this.data)
            return;
        this.data.Settings.splice(i, 1);
        if (this.data.Settings.length == 0)
            this.addNew();
    }
    addNew() {
        if (!this.data)
            return;
        this.data.Settings.push({
            ByItemId: undefined,
            Price: undefined,
            Type: ItemPurchaseType.Postage,
        });
    }
    other(index) {
        if (!this.data)
            return [];
        return this.data.Settings.map((v, i) => i == index ? "" : v.ByItemId);
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            yield post("/api/Mall/SetPostage", { Id: this.data.Id, Settings: this.data.Settings });
            this.$router.back();
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Inject()
], Page.prototype, "confirm", void 0);
__decorate([
    Prop()
], Page.prototype, "id", void 0);
Page = __decorate([
    Component({ components: { PointInput } })
], Page);
export default Page;
