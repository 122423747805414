/* eslint-disable max-lines */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { toDateString } from "@common/date";
import { EventGiveawayType } from "@/models/Owner";
const ONE_MONTH = 30;
let default_1 = class default_1 extends Vue {
    ticketTemplate() {
        if (!this.data || !this.state.outlet)
            return;
        this.data.Name = this.state.outlet.Name + "紙本兌換券" + toDateString(new Date(), "");
        this.data.Settings.Type = EventGiveawayType.Ticket;
        this.state.goal = 80;
        this.data.Settings.ReceiveLimit = null;
        this.data.Settings.FixedActivations = [{
                Amount: 80,
                Count: 1,
            }];
        const today = new Date().datePart();
        const end = today.addDays(ONE_MONTH - 1);
        this.state.range = [today, end];
        this.data.Settings._range = [today, end];
        this.callback();
    }
    linkTemplate() {
        if (!this.data || !this.state.outlet)
            return;
        this.data.Name = this.state.outlet.Name + "兌換連結條碼" + toDateString(new Date(), "");
        this.data.Settings.Type = EventGiveawayType.Link;
        this.state.goal = 80;
        this.data.Settings.ReceiveLimit = 1;
        this.data.Settings.FixedActivations = [{
                Amount: 80,
                Count: 1,
            }];
        const today = new Date().datePart();
        const end = today.addDays(ONE_MONTH - 1);
        this.state.range = [today, end];
        this.data.Settings._range = [today, end];
        this.callback();
    }
    useTemplate(el) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data || !this.template)
                return;
            const d = this.template.find(e => e.Id == el.value);
            el.value = "";
            if (!d)
                return;
            Vue.set(d.Settings, "_range", []);
            this.data.Name = d.Name;
            this.data.Remark = d.Remark;
            this.data.Settings = d.Settings;
            this.$refs.settingRange.onInit();
            this.callback();
            yield this.$nextTick();
            this.state.goal = this.$refs.Settings.totalCount;
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
__decorate([
    Prop()
], default_1.prototype, "state", void 0);
__decorate([
    Prop()
], default_1.prototype, "callback", void 0);
__decorate([
    Prop()
], default_1.prototype, "template", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
