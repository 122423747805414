var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import clone from "clone";
import { TINY_CLASS } from "@common/components/button";
import ButtonSelect from "@/components/ButtonSelect.vue";
import DetailOutlet from "./components/DetailOutlet.vue";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.editMode = false;
        this.tab = 0;
        this.filter = 0;
        this.TINY_CLASS = TINY_CLASS;
    }
    get title() {
        if (!this.data)
            return "";
        return `${this.data.Name}（${this.data.Username}）`;
    }
    get filtered() {
        if (!this.data)
            return [];
        if (this.filter == 0)
            return this.data.Outlets;
        const codename = this.filter == 1 ? "eventgiveaway" : "pointgiveaway";
        return this.data.Outlets.filter(o => o.CodeName == codename);
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Distributer/Detail", { Id: this.id });
        });
    }
    startEdit() {
        this.backup = clone(this.data);
        this.editMode = true;
    }
    cancelEdit() {
        this.data = this.backup;
        this.editMode = false;
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            // 整理模型差異
            const model = {
                Id: this.id,
                Settings: this.data.Settings,
                Outlets: [],
            };
            const oldOutlets = this.backup.Outlets;
            const newOutlets = this.data.Outlets;
            for (let i = 0; i < this.data.Outlets.length; i++) {
                let changed = false;
                let outlet = {
                    Id: newOutlets[i].Outlet.Id,
                    PromotionTypeId: newOutlets[i].PromotionTypeId,
                    Promotions: [],
                };
                if (newOutlets[i].EnableByDefault !== oldOutlets[i].EnableByDefault) {
                    changed = true;
                    outlet.EnableByDefault = newOutlets[i].EnableByDefault;
                }
                for (let j = 0; j < newOutlets[i].Promotions.length; j++) {
                    const promo = newOutlets[i].Promotions[j];
                    if (promo.IsActive !== oldOutlets[i].Promotions[j].IsActive) {
                        changed = true;
                        outlet.Promotions.push({ Id: promo.Promotion.Id, IsActive: promo.IsActive });
                    }
                }
                if (changed)
                    model.Outlets.push(outlet);
            }
            yield post("/api/Distributer/Edit", Object.assign({}, model));
            this.editMode = false;
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Inject()
], Page.prototype, "confirm", void 0);
__decorate([
    Prop()
], Page.prototype, "id", void 0);
Page = __decorate([
    Component({ components: { DetailOutlet, ButtonSelect } })
], Page);
export default Page;
