
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { holesBefore, serial } from "./util";

	import type { IchibanKujiAdminModel } from "@/models/owner/IchibanKuji";

	@Component
	export default class PrizeList extends Vue {
		@Prop() public data!: IchibanKujiAdminModel;

		get total(): number {
			return this.data.Settings.Prizes.reduce((x, v) => x + v.Amount, 0);
		}

		get noPrizeCount(): number {
			return this.data.Settings.HoleCount - this.total;
		}

		protected serial(n?: number): string {
			if(n === undefined) return serial(this.total, this.noPrizeCount);
			const at = holesBefore(this.data.Settings.Prizes, n);
			return serial(at, this.data.Settings.Prizes[n].Amount);
		}

		protected remain(n?: number): number {
			const amount = n === undefined ? this.noPrizeCount : this.data.Settings.Prizes[n].Amount;
			const claimed = this.data.Records.filter(r => r.PrizeId == n && r.ReceiveTime != null).length;
			return amount - claimed;
		}
	}
