
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	@Component
	export default class extends Vue {
		@Prop() public points!: number;

		@Inject() public alert!: AlertMethod;

		protected info(): void {
			this.alert(`<img src="/img/service_point.png" style="height:3rem" class="mb-2"><h5>LeaPA 服務點</h5><div>用於扣抵特店服務中，需付費的服務。</div>`);
		}

		protected add(): void {
			this.alert(`如須加值服務點，請洽 <a href="https://lin.ee/kUcmeAE" target="_blank">LeaPA 客服</a>。`);
		}
	}
