export var DeliveryMethodCode;
(function (DeliveryMethodCode) {
    DeliveryMethodCode[DeliveryMethodCode["Outlet"] = 1] = "Outlet";
    DeliveryMethodCode[DeliveryMethodCode["Post"] = 2] = "Post";
})(DeliveryMethodCode || (DeliveryMethodCode = {}));
export var ItemPurchaseType;
(function (ItemPurchaseType) {
    ItemPurchaseType[ItemPurchaseType["Main"] = 0] = "Main";
    ItemPurchaseType[ItemPurchaseType["Postage"] = 1] = "Postage";
})(ItemPurchaseType || (ItemPurchaseType = {}));
export var TransactionStatusCode;
(function (TransactionStatusCode) {
    TransactionStatusCode[TransactionStatusCode["Submitted"] = 1] = "Submitted";
    TransactionStatusCode[TransactionStatusCode["Received"] = 2] = "Received";
    TransactionStatusCode[TransactionStatusCode["Completed"] = 3] = "Completed";
    TransactionStatusCode[TransactionStatusCode["Cancelled"] = 4] = "Cancelled";
})(TransactionStatusCode || (TransactionStatusCode = {}));
export var PaidStatusCode;
(function (PaidStatusCode) {
    PaidStatusCode[PaidStatusCode["NotApplicable"] = 0] = "NotApplicable";
    PaidStatusCode[PaidStatusCode["Unpaid"] = 1] = "Unpaid";
    PaidStatusCode[PaidStatusCode["Paid"] = 2] = "Paid";
    PaidStatusCode[PaidStatusCode["Refund"] = 3] = "Refund";
})(PaidStatusCode || (PaidStatusCode = {}));
export var ShipStatusCode;
(function (ShipStatusCode) {
    ShipStatusCode[ShipStatusCode["NotApplicable"] = 0] = "NotApplicable";
    ShipStatusCode[ShipStatusCode["Preparing"] = 1] = "Preparing";
    ShipStatusCode[ShipStatusCode["Shipping"] = 2] = "Shipping";
    ShipStatusCode[ShipStatusCode["PickupReady"] = 3] = "PickupReady";
    ShipStatusCode[ShipStatusCode["Pickedup"] = 4] = "Pickedup";
    ShipStatusCode[ShipStatusCode["Confirmed"] = 5] = "Confirmed";
    ShipStatusCode[ShipStatusCode["Returned"] = 6] = "Returned";
})(ShipStatusCode || (ShipStatusCode = {}));
