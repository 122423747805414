var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Vue } from "vue-property-decorator";
import { MerchantType } from "@common/models/merchant";
import CircleStep from "@/components/CircleStep.vue";
import { option } from "@/views/merchant/outlet/Edit.vue";
import ServicePoints from "../service/components/ServicePoints.vue";
import DailySummary from "./eventGiveaway/components/DailySummary.vue";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
    }
    mounted() {
        this.load();
    }
    get terminalCount() {
        var _a;
        if (!this.data)
            return 0;
        return (_a = this.data.Merchant.Outlets) === null || _a === void 0 ? void 0 : _a.reduce((x, v) => { var _a; return x + ((_a = v.TerminalCount) !== null && _a !== void 0 ? _a : 0); }, 0);
    }
    get isOwner() {
        return this.data != null && Boolean(this.data.Merchant.MerchantType & MerchantType.ClawOutletOwner);
    }
    get title() {
        if (this.isOwner)
            return "場主功能";
        return "場主功能說明";
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Owner");
        });
    }
    apply() {
        this.$router.push("/owner/apply");
    }
    addOutlet() {
        option.showAlert = false;
        this.$router.push("/merchant/outlet/edit");
    }
    getSummary(code) {
        return this.data.Promotions.find(p => p.CodeName == code);
    }
    showHelp(help) {
        this.alert(help, "付費說明", "warning");
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
default_1 = __decorate([
    Component({
        components: { CircleStep, DailySummary, ServicePoints },
    })
], default_1);
export default default_1;
