
	import { Component, Inject, Vue } from "vue-property-decorator";

	import { MerchantType } from "@common/models/merchant";
	import CircleStep from "@/components/CircleStep.vue";
	import { option } from "@/views/merchant/outlet/Edit.vue";
	import ServicePoints from "../service/components/ServicePoints.vue";
	import DailySummary from "./eventGiveaway/components/DailySummary.vue";
	import type { OwnerIndexModel, PromotionSummary } from "@/models/Owner";

	@Component({
		components: { CircleStep, DailySummary, ServicePoints },
	})
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		protected data: OwnerIndexModel | null = null;

		mounted(): void {
			this.load();
		}

		protected get terminalCount(): number {
			if(!this.data) return 0;
			return this.data.Merchant.Outlets?.reduce((x, v) => x + (v.TerminalCount ?? 0), 0);
		}

		protected get isOwner(): boolean {
			return this.data != null && Boolean(this.data.Merchant.MerchantType & MerchantType.ClawOutletOwner);
		}

		protected get title(): string {
			if(this.isOwner) return "場主功能";
			return "場主功能說明";
		}

		private async load() {
			this.data = null;
			this.data = await post<OwnerIndexModel>("/api/Owner");
		}

		protected apply(): void {
			this.$router.push("/owner/apply");
		}

		protected addOutlet(): void {
			option.showAlert = false;
			this.$router.push("/merchant/outlet/edit");
		}

		protected getSummary(code: string): PromotionSummary {
			return this.data!.Promotions.find(p => p.CodeName == code)!;
		}

		protected showHelp(help: string): void {
			this.alert(help, "付費說明", "warning");
		}
	}
