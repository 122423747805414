
	import * as bootstrap from 'bootstrap';
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import clone from 'clone';

	import { newQuery } from '@/models/Owner';
	import FilterDistributer from './FilterDistributer.vue';

	import type { PromotionRecordQuery } from '@/models/Owner';
	import type { DistributerModel } from '@/models/owner/Distributer';
	import type DateRange from "@common/components/form/DateRange.vue";


	@Component({ components: { FilterDistributer } })
	export default class FilterModal extends Vue {
		$refs!: {
			range: DateRange;
		};

		@Prop() public query!: PromotionRecordQuery;

		private modal!: bootstrap.Modal;
		private value: boolean = false;
		protected options: DistributerModel[] = [];
		public temp: PromotionRecordQuery = {} as PromotionRecordQuery;

		mounted(): void {
			this.modal = new bootstrap.Modal(this.$el);
			post<DistributerModel[]>("/api/Distributer").then(v => {
				this.options = v;
				this.options.forEach(o => o.DisplayName = `${o.Name}（${o.Username}）`);
			});
		}

		public show(): Promise<boolean> {
			this.value = false;
			return new Promise<boolean>(resolve => {
				Vue.set(this, "temp", clone(this.query));
				this.$refs.range.reset(this.query.init);
				this.modal.show();
				this.$el.addEventListener('hidden.bs.modal', () => resolve(this.value), { once: true });
			});
		}

		protected onRange(e: [Date, Date]): void {
			if(e.length < 2) return;
			if(!e[1]) e[1] = e[0];
			[this.temp.Start, this.temp.End] = e;
		}

		protected ok(): void {
			Object.assign(this.query, this.temp);
			this.query.init = this.$refs.range.active;
			this.value = true;
		}

		protected reset(): void {
			Object.assign(this.temp, newQuery());
		}

		protected sync(): void {
			this.temp.Includes = this.temp.Includes.filter(id => !this.temp.Excludes.includes(id));
		}

		protected syncEx(): void {
			this.temp.Excludes = this.temp.Excludes.filter(id => !this.temp.Includes.includes(id));
		}
	}
