var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { statusText, statusClass } from "../../components/StatusString";
let default_1 = class default_1 extends Vue {
    get statusText() {
        if (!this.data)
            return "";
        const target = this.data.Settings.TargetPromotion;
        return statusText(target.Status, target.IsActive);
    }
    get statusClass() {
        if (!this.data)
            return "";
        const target = this.data.Settings.TargetPromotion;
        return statusClass(target.Status, target.IsActive);
    }
    get shareText() {
        return this.data ? statusText(this.data.Status, this.data.IsActive) : "";
    }
    get shareClass() {
        return this.data ? statusClass(this.data.Status, this.data.IsActive) : "";
    }
    toggle() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            if (!(yield this.confirm(this.data.IsActive ? `分享將暫停使用` : "分享將恢復使用", this.data.IsActive ? "分享暫停" : "分享啟用", "warning")))
                return;
            try {
                yield post("api/Owner/TogglePromotion", { Id: this.data.Id });
                this.alert(this.data.IsActive ? "完成分享暫停" : "完成分享啟用", undefined, "success");
                this.data.IsActive = !this.data.IsActive;
                this.$emit("change");
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "切換失敗", "error");
            }
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
__decorate([
    Prop()
], default_1.prototype, "unit", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "redeemToggle", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
