var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Vue } from "vue-property-decorator";
import { now, today } from "@common/date";
import Sorter, { sort } from "@/components/Sorter.vue";
import QRModal from "@/components/modal/QRModal.vue";
import StartEnd from "../components/StartEnd.vue";
import Promotion from "./components/Promotion.vue";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.now = now();
        this.start = today();
        this.end = today().addDays(1).addSeconds(-1);
        this.sorting = [0, false];
        this.sortingOption = [
            "創建時間", "活動狀態", "活動名稱",
        ];
        this.sortingSelectors = [
            (a) => a.CreateTime.getTime(),
            (a) => a.Status,
            (a) => a.Name,
        ];
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/IchibanKuji");
            // this.data.push({
            // 	Name: "測試活動",
            // 	Link: "https://www.google.com",
            // 	Claimed: 10,
            // 	Id: "ABC",
            // 	Status: 0,
            // 	IsActive: true,
            // 	StartTime: new Date(2024, 6, 1),
            // 	EndTime: new Date(2024, 11, 1),
            // 	Remark: "測試備註",
            // 	Settings: {
            // 		HoleCount: 35,
            // 	},
            // } as IchibanKujiAdminModel);
        });
    }
    get sorted() {
        if (!this.data)
            return [];
        return sort(this.data, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
    }
    onchange(e) {
        if (e.length < 2)
            return;
        if (!e[1])
            e[1] = e[0];
        [this.start, this.end] = e;
        this.load();
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
default_1 = __decorate([
    Component({ components: { Sorter, StartEnd, QRModal, Promotion } })
], default_1);
export default default_1;
