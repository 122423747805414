var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { hasTime } from "@common/date";
export function rangeText(range) {
    if (!range[0] || !range[1])
        return "";
    const end = range[1].datePart().getTime();
    const start = range[0].datePart().getTime();
    const showTime = hasTime(range[0], range[1]);
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const days = Math.floor((end - start) / 86400 / 1000) + 1;
    const startText = showTime ? range[0].minute() : range[0].date();
    const endText = showTime ? range[1].minute() : range[1].date();
    return startText + " - " + endText + "（共 " + days + " 天）";
}
let Range = class Range extends Vue {
    constructor() {
        super(...arguments);
        this.mode = 0;
    }
    onExMode(v) {
        if (!v) {
            this.data.ExpireDays = null;
            this.data.InitDays = null;
        }
        else {
            this.data._range = [];
        }
    }
    onInit() {
        if (typeof this.data.InitDays == "number") {
            this.mode = 2;
            // 向下相容
            if (this.data.InitHours === null) {
                this.data.InitHours = 0;
                this.data.ExpireHours = 0;
            }
        }
        else if (typeof this.data.ExpireDays == "number") {
            this.mode = 1;
        }
        else {
            this.mode = 0;
        }
    }
    onSubmit() {
        if (!this.mode) {
            this.data.StartTime = this.data._range[0];
            this.data.EndTime = this.data._range[1];
            this.data.InitDays = null;
            this.data.ExpireDays = null;
            this.data.InitHours = null;
            this.data.ExpireHours = null;
        }
        else {
            this.data.StartTime = null;
            this.data.EndTime = null;
            if (this.mode == 1) {
                this.data.InitDays = null;
                this.data.InitHours = null;
            }
        }
    }
    get rangeText() {
        return rangeText(this.data._range);
    }
    showSettingRange() {
        this.$refs.settingRange.show();
    }
};
__decorate([
    Prop()
], Range.prototype, "data", void 0);
__decorate([
    Watch("mode")
], Range.prototype, "onExMode", null);
Range = __decorate([
    Component
], Range);
export default Range;
