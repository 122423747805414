
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { DeliveryMethodCode, ItemPurchaseType } from "@/models/owner/Mall";
	import PointInput from "./PointInput.vue";

	import type { ItemPurchaseEditModel, MallItemEditModel } from "@/models/owner/Mall";

	@Component({ components: { PointInput } })
	export default class EditPriceCard extends Vue {
		@Prop() public data!: MallItemEditModel;

		mounted(): void {
			this.addFirst(ItemPurchaseType.Main);
			this.addFirst(ItemPurchaseType.Postage);
		}

		public get canPost(): boolean {
			return this.data.Settings.DeliveryMethods.includes(DeliveryMethodCode.Post);
		}

		public get main(): ItemPurchaseEditModel[] {
			return this.data.ItemPurchase.filter(p => p.Type == ItemPurchaseType.Main);
		}

		public get postage(): ItemPurchaseEditModel[] {
			return this.data.ItemPurchase.filter(p => p.Type == ItemPurchaseType.Postage);
		}

		protected remove(p: ItemPurchaseEditModel, type: ItemPurchaseType): void {
			this.data.ItemPurchase.splice(this.data.ItemPurchase.indexOf(p), 1);
			this.addFirst(type);
		}

		protected addFirst(type: ItemPurchaseType): void {
			if(!this.data.ItemPurchase.find(ip => ip.Type == type)) this.addNew(type);
		}

		protected addNew(type: ItemPurchaseType): void {
			this.data.ItemPurchase.push({
				Price: null!,
				Type: type,
			});
		}

		protected list(array: ItemPurchaseEditModel[], index: number): string[] {
			return array.map((v, i) => i == index ? "" : v.ByItemId!);
		}
	}
