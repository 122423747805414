
	import { Component, Inject, Vue } from "vue-property-decorator";

	import type { DistributerModel } from "@/models/owner/Distributer";

	@Component
	export default class Page extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		protected username: string = "";

		protected async send(): Promise<void> {
			const result = await post<DistributerModel>("/api/Distributer/Add", { Id: this.username });
			await this.alert(`${result.Name}（${result.Username}）`, "新增子帳號權限", "success");
			this.$router.back();
		}
	}
