
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { ItemPurchaseType } from "@/models/owner/Mall";
	import PointInput from "./components/PointInput.vue";

	import type { PostageEditModel } from "@/models/owner/Mall";

	type Data = PostageEditModel;

	@Component({ components: { PointInput } })
	export default class Page extends Vue {
		@Inject() public alert!: AlertMethod;
		@Inject() public confirm!: ConfirmMethod;

		@Prop() public id?: string;

		protected data: Data | null = null;
		protected hasPoint = false;

		mounted(): void {
			this.load();
		}

		private async load(): Promise<void> {
			this.data = null;
			this.data = await post<Data>("/api/Mall/GetPostageEditModel", { Id: this.id });
			this.hasPoint = this.data.Points.length > 0;
			if(this.data.Settings.length == 0) this.addNew();
		}

		protected remove(i: number): void {
			if(!this.data) return;
			this.data.Settings.splice(i, 1);
			if(this.data.Settings.length == 0) this.addNew();
		}

		protected addNew(): void {
			if(!this.data) return;
			this.data.Settings.push({
				ByItemId: undefined!,
				Price: undefined!,
				Type: ItemPurchaseType.Postage,
			});
		}

		protected other(index: number): string[] {
			if(!this.data) return [];
			return this.data.Settings.map((v, i) => i == index ? "" : v.ByItemId!);
		}

		protected async send(): Promise<void> {
			if(!this.data) return;
			await post<boolean>("/api/Mall/SetPostage", { Id: this.data.Id, Settings: this.data.Settings });
			this.$router.back();
		}
	}
