var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { DeliveryMethodCode, ShipStatusCode, TransactionStatusCode } from "@/models/owner/Mall";
import { deliveryMethodString, statusString } from "./utils";
let OrderCard = class OrderCard extends Vue {
    get method() {
        return this.data.TransactionShipment.length ? DeliveryMethodCode.Post : DeliveryMethodCode.Outlet;
    }
    get deliverMethod() {
        return deliveryMethodString([this.method]);
    }
    get status() {
        return statusString(this.data);
    }
    get canCancel() {
        // 可以取消的條件是：訂單尚未完成、且尚未寄出
        return this.data.Status == TransactionStatusCode.Received && this.data.ShipStatus != ShipStatusCode.Shipping;
    }
    get canShip() {
        return this.canCancel && this.method == DeliveryMethodCode.Post;
    }
};
__decorate([
    Prop()
], OrderCard.prototype, "data", void 0);
OrderCard = __decorate([
    Component({ components: {} })
], OrderCard);
export default OrderCard;
