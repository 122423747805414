
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { statusString } from "./utils";

	import type { TransactionModel } from "@/models/owner/Mall";

	@Component({ components: {} })
	export default class ModalTable extends Vue {
		@Prop() public data!: TransactionModel;
		@Prop(Boolean) public showStatus?: boolean;

		protected get status(): string {
			return statusString(this.data);
		}
	}
