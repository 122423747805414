var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { mark } from "@common/string";
import Sorter, { sort } from "@/components/Sorter.vue";
import PrizeList from "./PrizeList.vue";
import { formatHole } from "./util";
const NUMBER_SORT = 1000000;
let DetailCard = class DetailCard extends Vue {
    constructor() {
        super(...arguments);
        this.tabIndex = 0;
        this.sorting = [0, true];
        this.sortingOption = [
            "編號", "洞號", "出獎時間",
        ];
        this.sortingSelectors = [
            (a) => a.Serial,
            (a) => a.Number || NUMBER_SORT + a.Serial,
            (a) => a.ReceiveTime ? a.ReceiveTime.getTime() : Number.POSITIVE_INFINITY,
        ];
    }
    get sorted() {
        if (!this.data)
            return [];
        return sort(this.data.Records, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
    }
    username(r) {
        if (r.Username === null)
            return "未出獎";
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return mark(r.Username, "*", 4, 3);
    }
    name(r) {
        return mark(r.Name, "ｏ", 1, 1) || "（未命名）";
    }
    formatHole(n) {
        return formatHole(n);
    }
};
__decorate([
    Prop()
], DetailCard.prototype, "data", void 0);
DetailCard = __decorate([
    Component({ components: { Sorter, PrizeList } })
], DetailCard);
export default DetailCard;
