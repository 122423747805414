var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as bootstrap from 'bootstrap';
import { Component, Prop, Vue } from 'vue-property-decorator';
import clone from 'clone';
import { newQuery } from '@/models/Owner';
import FilterDistributer from './FilterDistributer.vue';
let FilterModal = class FilterModal extends Vue {
    constructor() {
        super(...arguments);
        this.value = false;
        this.options = [];
        this.temp = {};
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$el);
        post("/api/Distributer").then(v => {
            this.options = v;
            this.options.forEach(o => o.DisplayName = `${o.Name}（${o.Username}）`);
        });
    }
    show() {
        this.value = false;
        return new Promise(resolve => {
            Vue.set(this, "temp", clone(this.query));
            this.$refs.range.reset(this.query.init);
            this.modal.show();
            this.$el.addEventListener('hidden.bs.modal', () => resolve(this.value), { once: true });
        });
    }
    onRange(e) {
        if (e.length < 2)
            return;
        if (!e[1])
            e[1] = e[0];
        [this.temp.Start, this.temp.End] = e;
    }
    ok() {
        Object.assign(this.query, this.temp);
        this.query.init = this.$refs.range.active;
        this.value = true;
    }
    reset() {
        Object.assign(this.temp, newQuery());
    }
    sync() {
        this.temp.Includes = this.temp.Includes.filter(id => !this.temp.Excludes.includes(id));
    }
    syncEx() {
        this.temp.Excludes = this.temp.Excludes.filter(id => !this.temp.Includes.includes(id));
    }
};
__decorate([
    Prop()
], FilterModal.prototype, "query", void 0);
FilterModal = __decorate([
    Component({ components: { FilterDistributer } })
], FilterModal);
export default FilterModal;
