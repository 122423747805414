var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import RadioCheck from "@/components/form/RadioCheck.vue";
let DetailOutlet = class DetailOutlet extends Vue {
    get type() {
        return this.data.CodeName == "eventgiveaway" ? "活動送" : "集點送";
    }
    all(active) {
        if (!this.data)
            return false;
        return this.data.Promotions.every(p => Boolean(p.IsActive) == active);
    }
    setAll(active) {
        if (this.data)
            this.data.Promotions.forEach(p => p.IsActive = active);
    }
};
__decorate([
    Prop()
], DetailOutlet.prototype, "data", void 0);
__decorate([
    Prop()
], DetailOutlet.prototype, "editMode", void 0);
DetailOutlet = __decorate([
    Component({ components: { RadioCheck } })
], DetailOutlet);
export default DetailOutlet;
