var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { DeliveryMethodCode, ItemPurchaseType } from "@/models/owner/Mall";
import PointInput from "./PointInput.vue";
let EditPriceCard = class EditPriceCard extends Vue {
    mounted() {
        this.addFirst(ItemPurchaseType.Main);
        this.addFirst(ItemPurchaseType.Postage);
    }
    get canPost() {
        return this.data.Settings.DeliveryMethods.includes(DeliveryMethodCode.Post);
    }
    get main() {
        return this.data.ItemPurchase.filter(p => p.Type == ItemPurchaseType.Main);
    }
    get postage() {
        return this.data.ItemPurchase.filter(p => p.Type == ItemPurchaseType.Postage);
    }
    remove(p, type) {
        this.data.ItemPurchase.splice(this.data.ItemPurchase.indexOf(p), 1);
        this.addFirst(type);
    }
    addFirst(type) {
        if (!this.data.ItemPurchase.find(ip => ip.Type == type))
            this.addNew(type);
    }
    addNew(type) {
        this.data.ItemPurchase.push({
            Price: null,
            Type: type,
        });
    }
    list(array, index) {
        return array.map((v, i) => i == index ? "" : v.ByItemId);
    }
};
__decorate([
    Prop()
], EditPriceCard.prototype, "data", void 0);
EditPriceCard = __decorate([
    Component({ components: { PointInput } })
], EditPriceCard);
export default EditPriceCard;
