var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { giveawayTypeToString } from "@/models/Owner";
import { statusText, statusClass } from "../../components/StatusString";
import RangeDisplay from "../../components/RangeDisplay.vue";
import Badge from "../../components/Badge.vue";
import StartEnd from "../../components/StartEnd.vue";
let PromotionStatistics = class PromotionStatistics extends Vue {
    get typeText() {
        return giveawayTypeToString(this.data.Settings.Type);
    }
    get statusText() {
        return statusText(this.data.Status, this.data.IsActive);
    }
    get statusClass() {
        return statusClass(this.data.Status, this.data.IsActive).replace("text-", "");
    }
    get aStatusText() {
        return statusText(this.data.RedeemStatus);
    }
    get aStatusClass() {
        return statusClass(this.data.RedeemStatus).replace("text-", "");
    }
};
__decorate([
    Prop()
], PromotionStatistics.prototype, "data", void 0);
PromotionStatistics = __decorate([
    Component({ components: { Badge, RangeDisplay, StartEnd } })
], PromotionStatistics);
export default PromotionStatistics;
