

	import { Component, Inject, Watch } from "vue-property-decorator";
	import Modal from "@/components/modal";

	import { ItemSupplyStatus } from "@/models/Item";
	import type { MallItemModel } from "@/models/owner/Mall";

	type Data = MallItemModel;

	@Component
	export default class SupplyModal extends Modal {
		@Inject() public readonly alert!: AlertMethod;

		public model: Data | null = null;
		protected value: ItemSupplyStatus = ItemSupplyStatus.InStock;

		@Watch("model") onModel(v: Data): void {
			if(v) this.value = v.Item.SupplyStatus;
		}

		public async submit(): Promise<void> {
			if(!this.model) return;
			let model = this.model;
			let old = model.Item.SupplyStatus;
			model.Item.SupplyStatus = this.value;
			try {
				await post('/api/Mall/EditItemSupply', {
					Id: model.Item.Id,
					Value: this.value,
				});
				this.$emit("edit");
			} catch(e) {
				model.Item.SupplyStatus = old;
				let msg = e instanceof Error ? e.message : '儲存失敗，請稍後再試';
				await this.alert(msg, '', 'warning');
			}
			this.model = null;
		}
	}
