var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { statusText, statusClass } from "./StatusString";
let PromotionStatus = class PromotionStatus extends Vue {
    get statusText() {
        return this.data ? statusText(this.data.Status, this.data.IsActive) : "";
    }
    get statusClass() {
        return this.data ? statusClass(this.data.Status, this.data.IsActive) : "";
    }
    get redeemText() {
        return this.data ? statusText(this.data.RedeemStatus, this.data.IsRedeemActive) : "";
    }
    get redeemClass() {
        return this.data ? statusClass(this.data.RedeemStatus, this.data.IsRedeemActive) : "";
    }
    toggle() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            if (!(yield this.confirm(this.data.IsActive ? `兌換連結條碼/紙本兌換券將暫停兌換。已換得的${this.unit}數不受影響。` : "兌換連結條碼/紙本兌換券將恢復使用", this.data.IsActive ? "活動暫停" : "活動啟用", "warning")))
                return;
            try {
                yield post("api/Owner/TogglePromotion", { Id: this.data.Id });
                this.alert(this.data.IsActive ? "完成活動暫停" : "完成活動啟用", undefined, "success");
                this.data.IsActive = !this.data.IsActive;
                this.$emit("change");
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "切換失敗", "error");
            }
        });
    }
    toggleRedeem() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            if (!(yield this.confirm(this.data.IsRedeemActive ? `兌換連結條碼/紙本兌換券將暫停核銷。` : "核銷將恢復使用", this.data.IsRedeemActive ? "核銷暫停" : "核銷啟用", "warning")))
                return;
            try {
                yield post("api/Owner/TogglePromotion", { Id: this.data.Id, Name: "redeem" });
                this.alert(this.data.IsRedeemActive ? "完成核銷暫停" : "完成核銷啟用", undefined, "success");
                this.data.IsRedeemActive = !this.data.IsRedeemActive;
                this.$emit("change");
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "切換失敗", "error");
            }
        });
    }
};
__decorate([
    Inject()
], PromotionStatus.prototype, "confirm", void 0);
__decorate([
    Inject()
], PromotionStatus.prototype, "alert", void 0);
__decorate([
    Prop()
], PromotionStatus.prototype, "data", void 0);
__decorate([
    Prop()
], PromotionStatus.prototype, "unit", void 0);
__decorate([
    Prop(Boolean)
], PromotionStatus.prototype, "redeemToggle", void 0);
PromotionStatus = __decorate([
    Component
], PromotionStatus);
export default PromotionStatus;
