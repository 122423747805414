var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let BasicSetting = class BasicSetting extends Vue {
    get message() {
        const c = this.data.Settings.HoleCount;
        const p = this.data.Settings.ServicePoints;
        return c <= p ? `本次將扣 ${c} 點，剩 ${p - c} 點` : `本次將扣 ${c} 點，不足 ${c - p} 點`;
    }
};
__decorate([
    Prop()
], BasicSetting.prototype, "data", void 0);
BasicSetting = __decorate([
    Component
], BasicSetting);
export default BasicSetting;
