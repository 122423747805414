var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { ShareGiveawayRewardType } from "@/models/Owner";
let default_1 = class default_1 extends Vue {
    get condition() {
        if (this.data.RewardType == ShareGiveawayRewardType.AfterReceive)
            return "接收者領取獎品後";
        return "接收者使用完次數後";
    }
};
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
