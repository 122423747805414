
	import { Component, Prop, Vue } from "vue-property-decorator";

	import QRModal from "@/components/modal/QRModal.vue";
	import StockModal from "../modal/StockModal.vue";
	import SupplyModal from "../modal/SupplyModal.vue";
	import SupplyStatus from "./SupplyStatus.vue";

	import type { MallItemModel } from "@/models/owner/Mall";
	import type Toast from "@common/components/widget/Toast.vue";

	@Component({ components: { QRModal, SupplyStatus, StockModal, SupplyModal } })
	export default class ItemBasicCard extends Vue {
		$refs!: {
			qr: QRModal;
			supply: SupplyModal;
			stock: StockModal;
			toast: Toast;
		}

		@Prop() public data!: MallItemModel;

		protected editStock(): void {
			this.$refs.stock.model = this.data;
			this.$refs.stock.show();
		}

		protected supply(): void {
			this.$refs.supply.model = this.data;
			this.$refs.supply.show();
		}

		protected qr(): void {
			this.$refs.qr.show(this.data!.Link);
		}

		protected onCopy(): void {
			this.$refs.toast.show();
		}

	}
