
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { DeliveryMethodCode, ShipStatusCode, TransactionStatusCode } from "@/models/owner/Mall";
	import { deliveryMethodString, statusString } from "./utils";

	import type { TransactionModel } from "@/models/owner/Mall";

	@Component({ components: {} })
	export default class OrderCard extends Vue {
		@Prop() public data!: TransactionModel;

		public get method(): DeliveryMethodCode {
			return this.data.TransactionShipment.length ? DeliveryMethodCode.Post : DeliveryMethodCode.Outlet;
		}

		public get deliverMethod(): string {
			return deliveryMethodString([this.method]);
		}

		public get status(): string {
			return statusString(this.data);
		}

		public get canCancel(): boolean {
			// 可以取消的條件是：訂單尚未完成、且尚未寄出
			return this.data.Status == TransactionStatusCode.Received && this.data.ShipStatus != ShipStatusCode.Shipping;
		}

		public get canShip(): boolean {
			return this.canCancel && this.method == DeliveryMethodCode.Post;
		}
	}
