
	import { Component, Prop, Vue } from "vue-property-decorator";

	import { DeliveryMethodCode, ItemPurchaseType } from "@/models/owner/Mall";
	import { deliveryMethodString, formatOption } from "./utils";
	import type { ItemPurchaseModel, MallItemModel } from "@/models/owner/Mall";


	@Component({ components: {} })
	export default class ItemPriceCard extends Vue {
		@Prop() public data!: MallItemModel;

		get methods(): string {
			return deliveryMethodString(this.data.Settings.DeliveryMethods);
		}

		public get canPost(): boolean {
			return this.data.Settings.DeliveryMethods.includes(DeliveryMethodCode.Post);
		}

		public get main(): ItemPurchaseModel[] {
			return this.data.Purchases.filter(p => p.Type == ItemPurchaseType.Main);
		}

		public get postage(): ItemPurchaseModel[] {
			return this.data.Purchases.filter(p => p.Type == ItemPurchaseType.Postage);
		}

		public format(p: ItemPurchaseModel): string {
			return formatOption(p);
		}
	}
