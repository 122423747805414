var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import Sorter, { sort } from "@/components/Sorter.vue";
import { newQuery } from "@/models/Owner";
import DistributerRecord from "./components/DistributerRecord.vue";
import FilterModal from "./modal/FilterModal.vue";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.query = newQuery();
        this.data = null;
        this.sorting = [0, true];
        this.sortingOption = [
            "時間", "發送者",
        ];
        this.sortingSelectors = [
            (a) => a.CreateTime.getTime(),
            (a) => a.Distributer.Name,
        ];
    }
    get sorted() {
        if (!this.data)
            return [];
        return sort(this.data, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            try {
                this.data = yield post("/api/Distributer/PromotionRecord", Object.assign(Object.assign({}, this.query), { Id: this.id }));
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
                this.$router.back();
            }
        });
    }
    showFilter() {
        return __awaiter(this, void 0, void 0, function* () {
            if (yield this.$refs.modal.show())
                yield this.load();
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Prop(String)
], Page.prototype, "id", void 0);
Page = __decorate([
    Component({ components: { Sorter, DistributerRecord, FilterModal } })
], Page);
export default Page;
