var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { MINI_CLASS } from "@common/components/button";
import QRModal from "@/components/modal/QRModal.vue";
import StockModal from "../modal/StockModal.vue";
import SupplyModal from "../modal/SupplyModal.vue";
import SupplyStatus from "./SupplyStatus.vue";
let ItemBasicCard = class ItemBasicCard extends Vue {
    constructor() {
        super(...arguments);
        this.MINI_CLASS = MINI_CLASS;
    }
    editStock() {
        this.$refs.stock.model = this.data;
        this.$refs.stock.show();
    }
    supply() {
        this.$refs.supply.model = this.data;
        this.$refs.supply.show();
    }
    qr() {
        this.$refs.qr.show(this.data.Link);
    }
    onCopy() {
        this.$refs.toast.show();
    }
};
__decorate([
    Prop()
], ItemBasicCard.prototype, "data", void 0);
ItemBasicCard = __decorate([
    Component({ components: { QRModal, SupplyStatus, StockModal, SupplyModal } })
], ItemBasicCard);
export default ItemBasicCard;
