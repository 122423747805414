var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { PromotionStatus } from "@/models/Owner";
import Rules from "./Rules.vue";
let default_1 = class default_1 extends Vue {
    get statusText() {
        if (this.data.Status == PromotionStatus.Active)
            return "分享進行中";
        if (this.data.Status == PromotionStatus.Inactive)
            return "分享已暫停";
        if (this.data.Status == PromotionStatus.Expire)
            return "已結束";
        return "";
    }
    get statusClass() {
        if (this.data.Status == PromotionStatus.Active)
            return "success";
        if (this.data.Status == PromotionStatus.Inactive)
            return "warning";
        if (this.data.Status == PromotionStatus.Expire)
            return "danger";
        return "";
    }
};
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component({ components: { Rules } })
], default_1);
export default default_1;
