
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import { MultiSelect } from '@progress/kendo-vue-dropdowns';

	import type { PointGiveawayPointModel } from "@/models/Owner";

	@Component({
		components: { MultiSelect },
	})
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public id?: string;

		protected data: PointGiveawayPointModel | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = await post<PointGiveawayPointModel>("/api/Owner/GetPointEditModel", { Id: this.id });
		}

		protected async submit(): Promise<void> {
			if(!this.data) return;
			await post<boolean>("/api/Owner/EditPoint", { ...this.data });
			if(this.id) {
				await this.alert("儲存成功！", undefined, "success");
			} else {
				let msg = `<div class="row">
						<div class="col-7">點數種類</div>
						<div class="col-5 text-right">${this.data.Name}點</div>
						<div class="col-7">點數使用方式：</div>
						<div class="col-5 text-right">${this.data.Settings.ForIchibankuji ? "一番賞" : "集點送"}</div>
					</div>`;
				if(this.data.Settings.ForIchibankuji) {
					msg += `<hr>
					<div class="text-info text-center">
						* 使用於一番賞無須綁定門市<br>
						* 請前往一番賞創建活動發送點數
					</div>`;
				}
				await this.alert(msg, "新增成功", "success");
			}

			this.$router.push("/owner/pointGiveaway/points");
		}
	}
