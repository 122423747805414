
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import ItemRemarkModal from "./modal/ItemRemarkModal.vue";
	import ItemBasicCard from "./components/ItemBasicCard.vue";
	import ItemPriceCard from "./components/ItemPriceCard.vue";

	import type { MallItemModel } from "@/models/owner/Mall";

	type Data = MallItemModel;

	@Component({ components: { ItemRemarkModal, ItemBasicCard, ItemPriceCard } })
	export default class Page extends Vue {
		$refs!: {
			remark: ItemRemarkModal;
		}

		@Inject() public alert!: AlertMethod;

		@Prop() public id!: string;

		protected data: Data | null = null;

		get title(): string {
			return this.data?.Item.Data.Name ?? "";
		}

		mounted(): void {
			this.load();
		}

		private async load(): Promise<void> {
			this.data = null;
			this.data = await post<Data>("/api/Mall/GetItem", { Id: this.id });
		}

		protected editRemark(): void {
			this.$refs.remark.model = this.data;
			this.$refs.remark.show();
		}
	}
