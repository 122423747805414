
	import { Component, Prop, Vue } from "vue-property-decorator";

	import type { MallIndexModel } from "@/models/owner/Mall";

	type Data = MallIndexModel;

	@Component({ components: {} })
	export default class Page extends Vue {

		@Prop() public id?: string;

		protected data: Data | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			this.data = await post<Data>("/api/Mall", { Id: this.id });
		}
	}
