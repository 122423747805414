var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { EventGiveawayType } from "@/models/Owner";
import LimitNumber from "../components/LimitNumber.vue";
import Settings from "./components/Settings.vue";
import Range, { rangeText } from "./components/Range.vue";
import SetType from "./components/SetType.vue";
import EditTemplate from "./components/EditTemplate.vue";
import SettingAdvanced from "./components/SettingAdvanced.vue";
const SUBMIT_TIMEOUT = 60000;
const CODENAME = "eventgiveaway";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.state = {
            outlet: null,
            range: [],
            goal: 0,
        };
        this.template = null;
    }
    mounted() {
        post("/api/Owner/GetOutlet", { Id: this.outletId }).then(o => this.state.outlet = o);
        post("/api/Owner/GetRecentPromotion", { Name: CODENAME }).then(o => this.template = o);
        if (this.id) {
            this.load();
        }
        else {
            this.data = {
                Settings: {
                    Type: EventGiveawayType.Ticket,
                    RandomActivation: null,
                    RandomActivations: [],
                    FixedActivations: null,
                    DistributerActivation: null,
                    DisplayCount: false,
                    MixedDistribution: false,
                    _range: [],
                },
            };
        }
    }
    get EventGiveawayType() {
        return EventGiveawayType;
    }
    get title() {
        return this.id ? "編輯活動" : "新增活動";
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            const data = yield post("/api/Owner/GetPromotion", { Id: this.id, Name: CODENAME });
            data.Settings._range = [];
            this.data = data;
            if (this.id) {
                this.state.range = [this.data.StartTime, this.data.EndTime];
                if (this.data.Settings.StartTime && this.data.Settings.EndTime) {
                    this.data.Settings._range = [this.data.Settings.StartTime, this.data.Settings.EndTime];
                }
            }
        });
    }
    rangeText(range) {
        return rangeText(range);
    }
    showRange() {
        this.$refs.range.show();
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            this.data.CodeName = CODENAME;
            this.data.OutletId = this.outletId;
            this.data.StartTime = this.state.range[0];
            this.data.EndTime = this.state.range[1];
            this.$refs.settingRange.onSubmit();
            // 消除舊版資料，以防萬一
            this.data.Settings.ActivationCount = null;
            this.data.Settings.IssuanceLimit = null;
            yield post("/api/Owner/EditEventGiveawayPromotion", Object.assign({}, this.data), SUBMIT_TIMEOUT);
            yield this.alert("儲存成功！", undefined, "success");
            this.$router.push("/owner/eventGiveaway/promotions/" + this.outletId); // 這邊不能用 back()，不然不會刷新資料
        });
    }
    openAll() {
        for (const details of document.getElementsByTagName("details")) {
            details.open = true;
        }
    }
};
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "id", void 0);
__decorate([
    Prop()
], default_1.prototype, "outletId", void 0);
default_1 = __decorate([
    Component({ components: { LimitNumber, Settings, Range, SetType, EditTemplate, SettingAdvanced } })
], default_1);
export default default_1;
