var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { today } from "@common/date";
import Sorter, { sort } from "@/components/Sorter.vue";
import { setScroll } from "@/views/setScroll";
import { sum } from "@/views/utility";
import DailyModal from "../modal/dailyModal.vue";
import PromotionStatistics from "./components/PromotionStatistics.vue";
const TIMEOUT = 60000;
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.start = today();
        this.end = today().addDays(1).addSeconds(-1);
        this.init = 0;
        this.sorting = [0, false];
        this.sortingOption = [
            "創建時間", "名稱", "活動類型", "狀態", "票券取得效期", "啟動效期", "總領取次數", "總領取人數",
        ];
        this.sortingSelectors = [
            (a) => a.CreateTime.getTime(),
            (a) => a.AdminName,
            (a) => a.Settings.Type,
            (a) => a.Status,
            (a) => a.EndTime.getTime(),
            (a) => a.Settings.ExpireDays != null ? -a.Settings.ExpireDays : a.Settings.EndTime.getTime(),
            (a) => a.Statistics.ClaimCount,
            (a) => a.Statistics.ClaimUserCount,
        ];
    }
    mounted() {
        this.load();
    }
    beforeRouteLeave(to, from, next) {
        history.replaceState({
            scroll: document.querySelector('main').scrollTop,
            active: this.$refs.range.active,
            data: this.data,
        }, "");
        next();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            if (history.state.data) {
                this.data = history.state.data;
                const scroll = history.state.scroll;
                this.$refs.range.active = history.state.active;
                history.replaceState({}, "");
                Vue.nextTick(() => setScroll(scroll));
            }
            else {
                this.data = null;
                this.data = yield post("/api/Owner/PromotionStatistics", { Id: this.id, CodeName: "pointgiveaway", Start: this.start, End: this.end }, TIMEOUT);
            }
        });
    }
    reload(e) {
        this.start = e[0];
        this.end = e[1];
        this.load();
    }
    get sorted() {
        if (!this.data)
            return [];
        return sort(this.data.Promotions, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
    }
    add() {
        this.$router.push("/owner/pointGiveaway/edit/" + this.id);
    }
    sum(selector) {
        if (!this.data)
            return 0;
        return sum(this.data.Promotions, selector);
    }
    daily() {
        this.$refs.daily.show();
    }
};
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "id", void 0);
default_1 = __decorate([
    Component({
        components: { Sorter, PromotionStatistics, DailyModal },
    })
], default_1);
export default default_1;
