
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import Scanner from "@/components/Scanner.vue";

	@Component({ components: { Scanner } })
	export default class Page extends Vue {
		@Inject() public alert!: AlertMethod;

		@Prop() public id!: string;


		protected async scan(msg: string): Promise<void> {
			//
		}

		protected manual(): void {
			//
		}

	}
