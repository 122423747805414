var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Vue } from "vue-property-decorator";
import PrizeSetting from "./components/PrizeSetting.vue";
import BasicSetting from "./components/BasicSetting.vue";
import { remain } from "./components/util";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
    }
    get ok() {
        if (!this.data)
            return false;
        return this.data.Settings.HoleCount > 0 && // 要有洞
            remain(this.data.Settings) >= 0 && // 不能超過數量
            this.data.Settings.PointId != null; // 必須要選擇點數（不能光靠 validation 擋，因為有可能連點數都沒新增過）
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/IchibanKuji/GetEditModel");
            this.data.Settings.Price = null;
            this.data.Settings.HoleCount = null;
        });
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            let msg = "新增後將無法再修改";
            const remains = remain(this.data.Settings);
            if (remains) {
                msg = `尚有 ${remains} 洞獎品未設定<br>` + msg + `<div class="mt-3 text-danger">* 當有設定獎品的號碼抽完時，即活動結束<br>消費者無法再遊戲</div>`;
            }
            if (!(yield this.confirm(msg, "新增活動", "warning")))
                return;
            const payload = Object.assign(Object.assign({}, this.data), { CreateTime: undefined });
            yield post("/api/IchibanKuji/Edit", payload);
            yield this.alert("新增成功", undefined, "success");
            this.$router.back();
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Inject()
], Page.prototype, "confirm", void 0);
Page = __decorate([
    Component({ components: { PrizeSetting, BasicSetting } })
], Page);
export default Page;
