
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";

	import { hasTime } from "@common/date";

	import type { BaseGiveawayModel } from "@/models/Owner";
	import type DateTimeRangePicker from "@common/components/form/dateTimeRangePicker/DateTimeRangePicker.vue";

	export function rangeText(range: Date[]): string {
		if(!range[0] || !range[1]) return "";
		const end = range[1].datePart().getTime();
		const start = range[0].datePart().getTime();
		const showTime = hasTime(range[0], range[1]);
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		const days = Math.floor((end - start) / 86400 / 1000) + 1;
		const startText = showTime ? range[0].minute() : range[0].date();
		const endText = showTime ? range[1].minute() : range[1].date();
		return startText + " - " + endText + "（共 " + days + " 天）";
	}

	@Component
	export default class Range extends Vue {
		$refs!: {
			settingRange: DateTimeRangePicker,
		};

		@Prop() public data!: BaseGiveawayModel;

		protected mode: number = 0;

		@Watch("mode") onExMode(v: number): void {
			if(!v) {
				this.data.ExpireDays = null;
				this.data.InitDays = null;
			} else {
				this.data._range = [];
			}
		}

		public onInit(): void {
			if(typeof this.data.InitDays == "number") {
				this.mode = 2;
				// 向下相容
				if(this.data.InitHours === null) {
					this.data.InitHours = 0;
					this.data.ExpireHours = 0;
				}
			} else if(typeof this.data.ExpireDays == "number") {
				this.mode = 1;
			} else {
				this.mode = 0;
			}
		}

		public onSubmit(): void {
			if(!this.mode) {
				this.data.StartTime = this.data._range[0];
				this.data.EndTime = this.data._range[1];
				this.data.InitDays = null;
				this.data.ExpireDays = null;
				this.data.InitHours = null;
				this.data.ExpireHours = null;
			} else {
				this.data.StartTime = null;
				this.data.EndTime = null;
				if(this.mode == 1) {
					this.data.InitDays = null;
					this.data.InitHours = null;
				}
			}
		}

		protected get rangeText(): string {
			return rangeText(this.data._range);
		}

		protected showSettingRange(): void {
			this.$refs.settingRange.show();
		}
	}
