

	import { Component, Inject, Watch } from "vue-property-decorator";
	import Modal from "@/components/modal";

	import type { MallItemModel } from "@/models/owner/Mall";

	type Data = MallItemModel;

	@Component
	export default class ItemRemarkModal extends Modal {
		@Inject() public readonly alert!: AlertMethod;

		public model: Data | null = null;
		protected remark: string = "";

		@Watch("model") onModel(v: Data): void {
			if(v) this.remark = v.Settings.Remark;
		}

		public async submit(): Promise<void> {
			if(!this.model) return;
			let model = this.model;
			let old = model.Settings.Remark;
			model.Settings.Remark = this.remark;
			try {
				await post('/api/Mall/EditItemRemark', {
					Id: model.Item.Id,
					Name: this.remark,
				});
				this.$emit("edit");
			} catch(e) {
				model.Settings.Remark = old;
				let msg = e instanceof Error ? e.message : '儲存失敗，請稍後再試';
				await this.alert(msg, '', 'warning');
			}
			this.model = null;
		}
	}
