
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import { share } from "@common/jsInterface";
	import { EventGiveawayType } from "@/models/Owner";
	import QRModal from "@/components/modal/QRModal.vue";
	import PromotionStatusVue from "../components/PromotionStatus.vue";
	import PromotionHeader from "../components/PromotionHeader.vue";
	import SettingDetail from "../eventGiveaway/components/SettingDetail.vue";
	import TicketRecord from "../components/TicketRecord.vue";
	import RangeDisplay from "../components/RangeDisplay.vue";
	import Rules from "../eventGiveaway/components/Rules.vue";
	import ExcelModal from "./modal/ExcelModal.vue";
	import ClaimExcelModal from "./modal/ClaimExcelModal.vue";

	import type { PromotionDetailModel, PointGiveawayModel } from "@/models/Owner";
	import type Toast from "@common/components/widget/Toast.vue";
	import type { NavigationGuardNext, Route } from "vue-router";

	type Promotion = PromotionDetailModel<PointGiveawayModel>;

	let prev = "";

	@Component({
		components: {
			QRModal, ExcelModal, ClaimExcelModal, SettingDetail, TicketRecord,
			PromotionStatusVue, PromotionHeader, RangeDisplay, Rules,
		},
	})
	export default class extends Vue {
		$refs!: {
			qr: QRModal;
			toast: Toast;
			excel: ExcelModal;
			claim: ClaimExcelModal;
		};

		@Inject() public readonly confirm!: ConfirmMethod;
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public id!: string;

		protected data: Promotion | null = null;
		protected backUrl: string = "";
		protected prev: string = "";

		beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
			prev = from.path;
			next();
		}

		mounted(): void {
			this.load();
		}

		protected get LinkCode(): string {
			if(!this.data) return "";
			const match = this.data.Link.match(/[a-f0-9]+$/i);
			return match && match[0] || "";
		}

		protected get title(): string {
			if(!this.data) return "";
			return this.data.Name;
		}

		protected get EventGiveawayType(): typeof EventGiveawayType {
			return EventGiveawayType;
		}

		private async load(): Promise<void> {
			this.data = null;
			this.data = await post<Promotion>("/api/Owner/GetPromotion", { Id: this.id, Name: "pointgiveaway" });
		}

		protected setBack(): void {
			this.backUrl = prev;
		}

		protected qr(): void {
			this.$refs.qr.show(this.data!.Link);
		}

		protected async copy(): Promise<void> {
			await this.$clipboard(this.data!.Link);
			this.$refs.toast.show();
		}

		protected excel(): void {
			this.$refs.excel.show();
		}

		protected claimExcel(): void {
			this.$refs.claim.show();
		}

		protected share(): void {
			if(!this.data) return;
			share(this.data.Link, undefined, undefined);
		}
	}
