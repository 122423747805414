

	import { Component, Inject } from "vue-property-decorator";
	import Modal from "@/components/modal";
	import { ShipStatusCode } from "@/models/owner/Mall";
	import ModalTable from "../components/ModalTable.vue";

	import type { TransactionModel } from "@/models/owner/Mall";

	type Data = TransactionModel;

	@Component({ components: { ModalTable } })
	export default class ShipModal extends Modal {
		@Inject() public readonly alert!: AlertMethod;

		public model: Data | null = null;
		protected value: string = "";

		public async submit(): Promise<void> {
			if(!this.model) return;
			const model = this.model;
			const old = model.ShipStatus;
			model.ShipStatus = ShipStatusCode.Shipping;
			try {
				await post('/api/Mall/Ship', {
					Id: model.Id,
					Name: this.value,
				});
			} catch(e) {
				model.ShipStatus = old;
				let msg = e instanceof Error ? e.message : '操作失敗，請稍後再試';
				await this.alert(msg, '', 'warning');
			}
			this.model = null;
			this.value = "";
		}
	}
