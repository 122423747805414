
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import Sorter, { sort } from "@/components/Sorter.vue";
	import { newQuery } from "@/models/Owner";
	import DistributerRecord from "./components/DistributerRecord.vue";
	import FilterModal from "./modal/FilterModal.vue";

	import type { PromotionRecordQuery } from "@/models/Owner";
	import type { DistributerRecordModel } from "@/models/owner/Distributer";
	import type { Selector } from "@/components/Sorter.vue";

	const TIMEOUT = 60000;

	@Component({ components: { Sorter, DistributerRecord, FilterModal } })
	export default class Page extends Vue {
		@Inject() public readonly alert!: AlertMethod;
		@Prop(String) public id!: string; // PromotionId

		$refs!: {
			modal: FilterModal;
		};

		protected query: PromotionRecordQuery = newQuery();
		protected data: DistributerRecordModel[] | null = null;

		get sorted(): DistributerRecordModel[] {
			if(!this.data) return [];
			return sort(this.data, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
		}

		mounted(): void {
			this.load();
		}

		protected sorting: [number, boolean] = [0, true];
		protected sortingOption: string[] = [
			"時間", "發送者",
		];
		protected sortingSelectors: Selector<DistributerRecordModel>[] = [
			(a: DistributerRecordModel): number => a.CreateTime.getTime(),
			(a: DistributerRecordModel): string => a.Distributer.Name,
		];

		private async load(): Promise<void> {
			this.data = null;
			try {
				this.data = await post<DistributerRecordModel[]>("/api/Distributer/PromotionRecord", { ...this.query, Id: this.id }, TIMEOUT);
			} catch(e: unknown) {
				if(e instanceof Error) await this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
				this.$router.back();
			}
		}

		protected async showFilter(): Promise<void> {
			if(await this.$refs.modal.show()) await this.load();
		}
	}
