var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Watch } from "vue-property-decorator";
import Modal from "@/components/modal";
let RemarkModal = class RemarkModal extends Modal {
    constructor() {
        super(...arguments);
        this.model = null;
        this.remark = "";
    }
    onModel(v) {
        if (v)
            this.remark = v.Remark;
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.model)
                return;
            let model = this.model;
            let old = model.Remark;
            model.Remark = this.remark;
            try {
                yield post('/api/Owner/EditRemark', {
                    Id: model.Id,
                    Name: this.remark,
                });
                this.$emit("edit");
            }
            catch (e) {
                model.Remark = old;
                let msg = e instanceof Error ? e.message : '儲存失敗，請稍後再試';
                yield this.alert(msg, '', 'warning');
            }
            this.model = null;
        });
    }
};
__decorate([
    Inject()
], RemarkModal.prototype, "alert", void 0);
__decorate([
    Watch("model")
], RemarkModal.prototype, "onModel", null);
RemarkModal = __decorate([
    Component
], RemarkModal);
export default RemarkModal;
