var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import RemarkModal from "../modal/RemarkModal.vue";
let PromotionHeader = class PromotionHeader extends Vue {
    editRemark() {
        this.$refs.remark.model = this.data;
        this.$refs.remark.show();
    }
};
__decorate([
    Prop()
], PromotionHeader.prototype, "data", void 0);
PromotionHeader = __decorate([
    Component({ components: { RemarkModal } })
], PromotionHeader);
export default PromotionHeader;
