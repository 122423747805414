
	import { Component, Prop, Vue } from "vue-property-decorator";
	import type { PointGiveawayPointBaseModel } from "@/models/Owner";
	import type { ItemPurchaseEditModel } from "@/models/owner/Mall";

	@Component
	export default class PointInput extends Vue {
		@Prop() public data!: ItemPurchaseEditModel;
		@Prop() public others!: string[];
		@Prop() public range?: string[];
		@Prop() public msg?: string;
		@Prop() public placeholder!: string;
		@Prop() public options!: PointGiveawayPointBaseModel[];
	}
