
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import Sorter, { sort } from "@/components/Sorter.vue";
	import { ItemPurchaseType } from "@/models/owner/Mall";
	import SupplyStatus from "./components/SupplyStatus.vue";
	import ItemCard from "./components/ItemCard.vue";

	import type { MallItemIndexModel, MallItemModel } from "@/models/owner/Mall";
	import type { Selector } from "@/components/Sorter.vue";

	type Data = MallItemIndexModel;

	@Component({ components: { Sorter, SupplyStatus, ItemCard } })
	export default class Page extends Vue {
		@Inject() public alert!: AlertMethod;

		@Prop() public id!: string; // OutletId

		protected data: Data | null = null;

		protected sorting: [number, boolean] = [0, true];
		protected sortingOption: string[] = [
			"上架時間", "商品名稱", "價格", "庫存",
		];
		protected sortingSelectors: Selector<MallItemModel>[] = [
			(a: MallItemModel): number => a.Item.CreateTime.getTime(),
			(a: MallItemModel): string => a.Item.Name,
			(a: MallItemModel): number => a.Purchases.find(p => p.Type == ItemPurchaseType.Main)?.Price ?? 0,
			(a: MallItemModel): number => a.Item.Stock ?? 0,
		];
		protected get sorted(): MallItemModel[] {
			if(!this.data) return [];
			return sort(this.data.Items, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
		}

		mounted(): void {
			this.load();
		}

		private async load(): Promise<void> {
			this.data = null;
			this.data = await post<Data>("/api/Mall/Items", { Id: this.id });
		}
	}
