var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import LimitNumber from "../../components/LimitNumber.vue";
let SettingDetail = class SettingDetail extends Vue {
    mounted() {
        // 向下相容
        if (this.data.IssuanceLimit != null && this.data.ActivationCount != null && this.data.FixedActivations == null) {
            this.data.FixedActivations = [{
                    Amount: this.data.IssuanceLimit,
                    Count: this.data.ActivationCount,
                }];
        }
    }
    get totalAmount() {
        return this.fixedAmount + this.randomAmount;
    }
    get totalCount() {
        return this.fixedCount + this.randomCount;
    }
    get fixedAmount() {
        if (!this.data.FixedActivations)
            return 0;
        let amount = 0;
        for (const a of this.data.FixedActivations) {
            amount += a.Amount;
        }
        return amount;
    }
    get fixedCount() {
        if (!this.data.FixedActivations)
            return 0;
        let count = 0;
        for (const a of this.data.FixedActivations) {
            count += a.Count * a.Amount;
        }
        return count;
    }
    get randomAmount() {
        if (!this.data.RandomActivations)
            return 0;
        let amount = 0;
        for (const a of this.data.RandomActivations) {
            amount += a.Amount;
        }
        return amount;
    }
    get randomCount() {
        if (!this.data.RandomActivations)
            return 0;
        let count = 0;
        for (const a of this.data.RandomActivations) {
            count += a.Count * a.Amount;
        }
        return count;
    }
};
__decorate([
    Prop()
], SettingDetail.prototype, "data", void 0);
__decorate([
    Prop()
], SettingDetail.prototype, "unit", void 0);
SettingDetail = __decorate([
    Component({ components: { LimitNumber } })
], SettingDetail);
export default SettingDetail;
