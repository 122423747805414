var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { share } from "@common/jsInterface";
import { EventGiveawayType } from "@/models/Owner";
import PromotionHeader from "../components/PromotionHeader.vue";
import TicketRecord from "./components/TicketRecord.vue";
import PromotionStatusVue from "./components/PromotionStatus.vue";
import ExcelModal from "./modal/ExcelModal.vue";
import Rules from "./components/Rules.vue";
let prev = "";
let fromEdit = false;
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.backUrl = "";
        this.prev = "";
    }
    beforeRouteEnter(to, from, next) {
        if (!from.path.toLowerCase().includes("edit"))
            prev = from.path;
        else
            fromEdit = true;
        next();
    }
    mounted() {
        this.load();
        if (fromEdit)
            this.setBack();
        fromEdit = false;
    }
    get LinkCode() {
        if (!this.data)
            return "";
        const match = this.data.Link.match(/[a-f0-9]+$/i);
        return match && match[0] || "";
    }
    get title() {
        if (!this.data)
            return "";
        return this.data.Settings.TargetPromotion.Name;
    }
    get EventGiveawayType() {
        return EventGiveawayType;
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Owner/GetPromotion", { Id: this.id, Name: "sharegiveaway" });
        });
    }
    setBack() {
        if (!this.backUrl)
            this.backUrl = prev;
    }
    excel() {
        this.$refs.excel.show();
    }
    share() {
        if (!this.data)
            return;
        share(this.data.Link, undefined, undefined);
    }
    edit() {
        this.$router.push("/owner/shareGiveaway/edit/" + this.data.OutletId + "/" + this.id);
    }
    toEvent() {
        this.$router.push("/owner/eventgiveaway/detail/" + this.data.Settings.TargetId);
    }
    sum(items, factory) {
        return items.reduce((x, v) => x + factory(v), 0);
    }
};
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "id", void 0);
default_1 = __decorate([
    Component({
        components: {
            ExcelModal, Rules, TicketRecord, PromotionStatusVue, PromotionHeader,
        },
    })
], default_1);
export default default_1;
