
	import { Component, Inject, Vue } from "vue-property-decorator";
	import Distributer from "./components/Distributer.vue";

	import type { DistributerModel } from "@/models/owner/Distributer";

	@Component({ components: { Distributer } })
	export default class Page extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		protected data: DistributerModel[] | null = null;

		mounted(): void {
			this.load();
		}

		get active(): DistributerModel[] {
			if(!this.data) return [];
			return this.data.filter(e => e.IsActive);
		}

		get inactive(): DistributerModel[] {
			if(!this.data) return [];
			return this.data.filter(e => !e.IsActive);
		}

		private async load() {
			this.data = null;
			this.data = await post<DistributerModel[]>("/api/Distributer");
		}
	}
