var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Vue } from "vue-property-decorator";
import DistributerItem from "./components/DistributerItem.vue";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.selected = "";
        this.data = null;
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = (yield post("/api/Distributer")).filter(d => d.IsActive);
        });
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.confirm("刪除子帳號後，該子帳號將無相關權限", "確認刪除子帳號", "danger")))
                return;
            yield post("/api/Distributer/Remove", { Id: this.selected });
            yield this.alert("刪除成功", undefined, "success");
            this.$router.back();
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Inject()
], Page.prototype, "confirm", void 0);
Page = __decorate([
    Component({ components: { DistributerItem } })
], Page);
export default Page;
