import { TransactionStatusCode } from "@/models/owner/Mall";

import type { DeliveryMethodCode, ItemPurchaseModel, TransactionModel } from "@/models/owner/Mall";

export function deliveryMethodString(methods: DeliveryMethodCode[]): string {
	const map = ["無", "面交", "郵寄"];
	if(methods.length == 0) return map[0];
	return methods.map(m => map[m]).join(" / ");
}

export function formatOption(p: ItemPurchaseModel): string {
	return `${p.ByItem.Name}點 ${p.Price} 點`;
}

export function statusString(transaction: TransactionModel): string {
	if(transaction.Status == TransactionStatusCode.Received) return "已收到訂單";
	if(transaction.Status == TransactionStatusCode.Completed) return "完成收貨";
	if(transaction.Status == TransactionStatusCode.Cancelled) {
		const log = transaction.TransactionLog.find(l => l.Change.Status == TransactionStatusCode.Cancelled);
		if(log) return `已取消（${log.Change.Remark}）`;
	}
	return "";
}
