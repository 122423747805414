var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import CircleStep from "@/components/CircleStep.vue";
import { TerminalPromotionStatus } from "@/models/Owner";
import Sorter, { sort } from "@/components/Sorter.vue";
import DailySummary from "./components/DailySummary.vue";
function dialogHTML(t) {
    return `${t.MerchantKiosk && t.MerchantKiosk.Name || "未命名"}<div class="text-muted">${t.Kiosk.DisplayCode}</div>`;
}
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.sorting = [0, true];
        this.sortingOption = [
            "名稱", "機台編號", "狀態",
        ];
        this.sortingSelectors = [
            (a) => a.Name,
            (a) => a.Kiosk.DisplayCode,
            (a) => { var _a; return (_a = a.Status) !== null && _a !== void 0 ? _a : 0; },
        ];
        this.resumeName = "";
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Owner/PromotionTerminal", { Id: this.id, Name: "eventgiveaway" });
        });
    }
    get TerminalPromotionStatus() {
        return TerminalPromotionStatus;
    }
    count(status) {
        return this.data.Terminals.filter(t => t.Status == status || status == 0 && !t.Status).length;
    }
    get sorted() {
        if (!this.data)
            return [];
        return sort(this.data.Terminals, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
    }
    activate(t) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.confirm(dialogHTML(t), "確定啟用活動送？")))
                return;
            try {
                yield post("/api/Owner/Invite", { Id: t.Id, Name: "eventgiveaway" });
                t.Status = TerminalPromotionStatus.Activated;
                yield this.alert(dialogHTML(t), "已成功啟用", "success");
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "啟用失敗");
            }
        });
    }
    invite(t) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.confirm(dialogHTML(t), "邀請成為核銷點？")))
                return;
            try {
                yield post("/api/Owner/Invite", { Id: t.Id, Name: "eventgiveaway" });
                t.Status = TerminalPromotionStatus.Inviting;
                yield this.alert(dialogHTML(t), "已送出邀請", "success");
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "邀請失敗");
            }
        });
    }
    stop(t) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.confirm(dialogHTML(t), "確定停用活動送？")))
                return;
            try {
                yield post("/api/Owner/Stop", { Id: t.Id, Name: "eventgiveaway" });
                t.Status = TerminalPromotionStatus.Activated;
                yield this.alert(dialogHTML(t), "已成功停用", "success");
                this.load();
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "停用失敗");
            }
        });
    }
    resume(t) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield post("/api/Owner/Resume", { Id: t.Id, Name: "eventgiveaway" });
                t.Status = TerminalPromotionStatus.Activated;
                this.resumeName = t.Name;
                this.$refs.toast.show();
                this.load();
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "啟用失敗");
            }
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "id", void 0);
default_1 = __decorate([
    Component({
        components: { DailySummary, CircleStep, Sorter },
    })
], default_1);
export default default_1;
