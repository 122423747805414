
	import { Component, Inject, Vue } from "vue-property-decorator";

	import { TEN_SECONDS } from "@common/communication";
	import { MerchantType } from "@common/models/merchant";
	import type { MerchantApplyModel } from "@common/models/merchant";
	import type { NavigationGuardNext, Route } from "vue-router";

	// 用來快取表單資料的靜態變數
	let cache: MerchantApplyModel | null = null;

	@Component
	export default class extends Vue {

		@Inject() public readonly alert!: AlertMethod;

		protected data: MerchantApplyModel | null = null;
		protected lock: string[] = [];

		beforeMount(): void {
			// 還原表單並且清除快取
			if(cache) this.data = cache;
			cache = null;
		}

		mounted(): void {
			this.load();
		}

		beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
			// 只有當即將前往條款頁面的時候才記憶資料
			if(to.path == '/login/terms') cache = this.data;
			next();
		}

		private async load() {
			this.data = await post<MerchantApplyModel>("/api/Merchant/Edit");
			this.data.MerchantType |= MerchantType.ClawOutletOwner;
		}

		protected copy(): void {
			if(!this.data) return;
			this.data.AdministratorContact.Name = this.data.LiaisonContact.Name;
			this.data.AdministratorContact.Phone = this.data.LiaisonContact.Phone;
			this.data.AdministratorContact.Email = this.data.LiaisonContact.Email;
		}

		protected async send(): Promise<void> {
			const emailSent = await post<boolean>('/api/Merchant/Apply', { ...this.data }, TEN_SECONDS);
			await this.alert(
				emailSent ? '您已升級為場主，\n請前往電子信箱收驗證信，\n方便後續開通場主功能。' : '您已升級為場主！',
				'升級成功', 'success'
			);
			this.$router.back();
		}
	}
