var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { PromotionStatus } from "@/models/Owner";
let StatusBtn = class StatusBtn extends Vue {
    get showBtn() {
        return this.data.Status != PromotionStatus.Expire; // 還沒開始也可以暫停
    }
    toggle() {
        return __awaiter(this, void 0, void 0, function* () {
            const wasActive = this.data.Status == PromotionStatus.Active;
            const msg = wasActive ? "消費者將無法進行遊戲" : "消費者將可以開始進行遊戲";
            const title = wasActive ? "活動暫停" : "活動恢復";
            if (!(yield this.confirm(msg, title, "warning", "確認", "取消")))
                return;
            yield post("/api/Owner/TogglePromotion", { Id: this.data.Id });
            this.data.Status = wasActive ? PromotionStatus.Inactive : PromotionStatus.Active;
            this.data.IsActive = !this.data.IsActive;
        });
    }
};
__decorate([
    Inject()
], StatusBtn.prototype, "confirm", void 0);
__decorate([
    Prop({ default: "BtnTiny" })
], StatusBtn.prototype, "as", void 0);
__decorate([
    Prop()
], StatusBtn.prototype, "data", void 0);
__decorate([
    Prop({ default: "" })
], StatusBtn.prototype, "prefix", void 0);
StatusBtn = __decorate([
    Component
], StatusBtn);
export default StatusBtn;
