var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { ShareGiveawayRewardType } from "@/models/Owner";
import LimitNumber from "../components/LimitNumber.vue";
import Settings from "../components/settings/Settings.vue";
import Range from "../components/settings/Range.vue";
const SUBMIT_TIMEOUT = 60000;
const CODENAME = "sharegiveaway";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.options = null;
        this.limit = true;
    }
    mounted() {
        if (this.id) {
            this.load();
        }
        else {
            post("/api/Owner/GetShareablePromotions", { Id: this.outletId }).then(o => this.options = o);
            this.data = {
                Settings: {
                    RewardType: ShareGiveawayRewardType.AfterUse,
                },
            };
        }
    }
    get ShareGiveawayRewardType() {
        return ShareGiveawayRewardType;
    }
    get title() {
        return this.id ? "編輯活動" : "新增活動";
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Owner/GetPromotion", { Id: this.id, Name: CODENAME });
            this.limit = typeof this.data.Settings.ShareLimit == "number";
        });
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            if (!this.limit)
                this.data.Settings.ShareLimit = null;
            const data = Object.assign({}, this.data);
            data.CodeName = CODENAME;
            data.OutletId = this.outletId;
            data.StartTime = null;
            data.EndTime = null;
            yield post("/api/Owner/EditShareGiveawayPromotion", data, SUBMIT_TIMEOUT);
            yield this.alert("儲存成功！", undefined, "success"); // 基本上這邊不會失敗
            if (this.id)
                this.$router.push("/owner/shareGiveaway/detail/" + this.id); // 會是從這邊過來的
            else
                this.$router.push("/owner/shareGiveaway/promotions/" + this.outletId); // 這邊不能用 back()，不然不會刷新資料
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "id", void 0);
__decorate([
    Prop()
], default_1.prototype, "outletId", void 0);
default_1 = __decorate([
    Component({ components: { LimitNumber, Settings, Range } })
], default_1);
export default default_1;
