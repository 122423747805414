var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { sum, purge } from "@/views/utility";
import { EventGiveawayType } from "@/models/Owner";
let TicketRecord = class TicketRecord extends Vue {
    constructor() {
        super(...arguments);
        this.sum = sum;
        this.purge = purge;
    }
    get EventGiveawayType() {
        return EventGiveawayType;
    }
    distributerRecord() {
        this.$router.push("/owner/distributer/promotionRecord/" + this.data.Id);
    }
};
__decorate([
    Prop()
], TicketRecord.prototype, "data", void 0);
__decorate([
    Prop()
], TicketRecord.prototype, "label", void 0);
__decorate([
    Prop()
], TicketRecord.prototype, "unit", void 0);
__decorate([
    Prop(Boolean)
], TicketRecord.prototype, "distributer", void 0);
TicketRecord = __decorate([
    Component
], TicketRecord);
export default TicketRecord;
