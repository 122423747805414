
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { EventGiveawayType } from "@/models/Owner";
	import LimitNumber from "../components/LimitNumber.vue";
	import Settings from "../components/settings/Settings.vue";
	import Range, { rangeText } from "../components/settings/Range.vue";
	import SetType from "../components/settings/SetType.vue";
	import EditTemplate from "../components/settings/EditTemplate.vue";
	import SettingAdvanced from "../components/settings/SettingAdvanced.vue";

	import type { EditState } from "../components/settings/types";
	import type { OutletModel } from "@common/models/merchant";
	import type { PromotionModel, BaseGiveawayModel, Activation } from "@/models/Owner";
	import type DateTimeRangePicker from "@common/components/form/dateTimeRangePicker/DateTimeRangePicker.vue";

	type Promotion = PromotionModel<BaseGiveawayModel>;

	const SUBMIT_TIMEOUT = 60000;
	const CODENAME = "eventgiveaway";

	@Component({ components: { LimitNumber, Settings, Range, SetType, EditTemplate, SettingAdvanced } })
	export default class extends Vue {
		$refs!: {
			Settings: Settings,
			range: DateTimeRangePicker,
			settingRange: Range,
		};

		@Inject() public readonly confirm!: ConfirmMethod;
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public id?: string;
		@Prop() public outletId!: string;

		protected data: Promotion | null = null;
		protected state: EditState = {
			outlet: null,
			range: [],
			goal: 0,
		};
		protected template: Promotion[] | null = null;

		mounted(): void {
			post<OutletModel>("/api/Owner/GetOutlet", { Id: this.outletId }).then(o => this.state.outlet = o);
			post<Promotion[]>("/api/Owner/GetRecentPromotion", { Name: CODENAME }).then(o => this.template = o);
			if(this.id) {
				this.load();
			} else {
				this.data = {
					Settings: {
						Type: EventGiveawayType.Ticket,
						RandomActivation: null,
						RandomActivations: [] as Activation[],
						FixedActivations: null,
						DistributerActivation: null,
						DisplayCount: false,
						MixedDistribution: false,
						_range: [] as Date[],
					} as BaseGiveawayModel,
				} as Promotion;
			}
		}

		protected get EventGiveawayType(): typeof EventGiveawayType {
			return EventGiveawayType;
		}

		protected get title(): string {
			return this.id ? "編輯活動" : "新增活動";
		}

		private async load(): Promise<void> {
			this.data = null;
			const data = await post<Promotion>("/api/Owner/GetPromotion", { Id: this.id, Name: CODENAME });
			data.Settings._range = [];
			this.data = data;
			if(this.id) {
				this.state.range = [this.data.StartTime, this.data.EndTime];
				if(this.data.Settings.StartTime && this.data.Settings.EndTime) {
					this.data.Settings._range = [this.data.Settings.StartTime, this.data.Settings.EndTime];
				}
			}
		}

		protected rangeText(range: Date[]): string {
			return rangeText(range);
		}

		protected showRange(): void {
			this.$refs.range.show();
		}

		protected async submit(): Promise<void> {
			if(!this.data) return;
			this.data.CodeName = CODENAME;
			this.data.OutletId = this.outletId;
			this.data.StartTime = this.state.range[0];
			this.data.EndTime = this.state.range[1];
			this.$refs.settingRange.onSubmit();

			// 消除舊版資料，以防萬一
			this.data.Settings.ActivationCount = null;
			this.data.Settings.IssuanceLimit = null;

			await post<boolean>("/api/Owner/EditEventGiveawayPromotion", { ...this.data }, SUBMIT_TIMEOUT);
			await this.alert("儲存成功！", undefined, "success");
			this.$router.push("/owner/eventGiveaway/promotions/" + this.outletId); // 這邊不能用 back()，不然不會刷新資料
		}

		protected async onTemplate(): Promise<void> {
			this.$refs.settingRange.onInit();
			for(const details of document.getElementsByTagName("details")) {
				details.open = true;
			}
			await this.$nextTick();
			this.state.goal = this.$refs.Settings.totalCount;
		}
	}
