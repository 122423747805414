var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { now, today } from "@common/date";
import Sorter, { sort } from "@/components/Sorter.vue";
import DistributerRecord from "./components/DistributerRecord.vue";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.now = now();
        this.start = today();
        this.end = today().addDays(1).addSeconds(-1);
        this.data = null;
        this.sorting = [0, true];
        this.sortingOption = [
            "時間", "活動名稱",
        ];
        this.sortingSelectors = [
            (a) => a.CreateTime.getTime(),
            (a) => a.Promotion.Name,
        ];
    }
    get sorted() {
        if (!this.data)
            return [];
        return sort(this.data.Records, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
    }
    mounted() {
        this.load();
    }
    onchange(e) {
        if (e.length < 2)
            return;
        if (!e[1])
            e[1] = e[0];
        [this.start, this.end] = e;
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.data = yield post("/api/Distributer/Record", {
                    Id: this.id,
                    Start: this.start.toIsoTime(),
                    End: this.end.toIsoTime(),
                });
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
                this.$router.back();
            }
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Prop(String)
], Page.prototype, "id", void 0);
Page = __decorate([
    Component({ components: { Sorter, DistributerRecord } })
], Page);
export default Page;
