var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import Sorter, { sort } from "@/components/Sorter.vue";
import { ItemPurchaseType } from "@/models/owner/Mall";
import SupplyStatus from "./components/SupplyStatus.vue";
import ItemCard from "./components/ItemCard.vue";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.sorting = [0, true];
        this.sortingOption = [
            "上架時間", "商品名稱", "價格", "庫存",
        ];
        this.sortingSelectors = [
            (a) => a.Item.CreateTime.getTime(),
            (a) => a.Item.Name,
            (a) => { var _a, _b; return (_b = (_a = a.Purchases.find(p => p.Type == ItemPurchaseType.Main)) === null || _a === void 0 ? void 0 : _a.Price) !== null && _b !== void 0 ? _b : 0; },
            (a) => { var _a; return (_a = a.Item.Stock) !== null && _a !== void 0 ? _a : 0; },
        ];
    }
    get sorted() {
        if (!this.data)
            return [];
        return sort(this.data.Items, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Mall/Items", { Id: this.id });
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Prop()
], Page.prototype, "id", void 0);
Page = __decorate([
    Component({ components: { Sorter, SupplyStatus, ItemCard } })
], Page);
export default Page;
