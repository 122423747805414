var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject } from "vue-property-decorator";
import Modal from "@/components/modal";
import { ShipStatusCode, TransactionStatusCode } from "@/models/owner/Mall";
import ModalTable from "../components/ModalTable.vue";
let CancelModal = class CancelModal extends Modal {
    constructor() {
        super(...arguments);
        this.model = null;
        this.value = "";
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.model)
                return;
            const model = this.model;
            const oldStatus = model.Status;
            const oldShip = model.ShipStatus;
            model.Status = TransactionStatusCode.Cancelled;
            model.ShipStatus = ShipStatusCode.NotApplicable;
            try {
                yield post('/api/Mall/Cancel', {
                    Id: model.Id,
                    Name: this.value,
                });
            }
            catch (e) {
                model.Status = oldStatus;
                model.ShipStatus = oldShip;
                let msg = e instanceof Error ? e.message : '操作失敗，請稍後再試';
                yield this.alert(msg, '', 'warning');
            }
            this.model = null;
            this.value = "";
        });
    }
};
__decorate([
    Inject()
], CancelModal.prototype, "alert", void 0);
CancelModal = __decorate([
    Component({ components: { ModalTable } })
], CancelModal);
export default CancelModal;
