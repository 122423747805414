

	import { Component, Inject, Watch } from "vue-property-decorator";
	import Modal from "@/components/modal";

	import ModalTable from "../components/ModalTable.vue";
	import type { TransactionModel } from "@/models/owner/Mall";

	type Data = TransactionModel;

	@Component({ components: { ModalTable } })
	export default class OrderRemarkModal extends Modal {
		@Inject() public readonly alert!: AlertMethod;

		public model: Data | null = null;
		protected remark: string = "";

		@Watch("model") onModel(v: Data): void {
			if(v) this.remark = v.Comment;
		}

		public async submit(): Promise<void> {
			if(!this.model) return;
			let model = this.model;
			let old = model.Comment;
			model.Comment = this.remark;
			try {
				await post('/api/Mall/EditOrderRemark', {
					Id: model.Id,
					Name: this.remark,
				});
				this.$emit("edit");
			} catch(e) {
				model.Comment = old;
				let msg = e instanceof Error ? e.message : '儲存失敗，請稍後再試';
				await this.alert(msg, '', 'warning');
			}
			this.model = null;
		}
	}
