var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { MAX_FIXED_ACTIVATION } from "./types";
let FixedInput = class FixedInput extends Vue {
    get limit() {
        if (this.old === undefined)
            return "十";
        else
            return " " + (MAX_FIXED_ACTIVATION - this.old) + " ";
    }
    removeFixed() {
        this.data.pop();
    }
    addFixed() {
        this.data.push({
            Amount: 0,
            Count: 0,
        });
    }
    get canAddFixed() {
        return this.data.length + (this.old || 0) < MAX_FIXED_ACTIVATION;
    }
};
__decorate([
    Prop()
], FixedInput.prototype, "data", void 0);
__decorate([
    Prop()
], FixedInput.prototype, "unit", void 0);
__decorate([
    Prop()
], FixedInput.prototype, "old", void 0);
FixedInput = __decorate([
    Component({ components: {} })
], FixedInput);
export default FixedInput;
