var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { serial } from "./util";
let PrizeInput = class PrizeInput extends Vue {
    constructor() {
        super(...arguments);
        this.uploads = [];
    }
    onUpload() {
        this.data.PhotoUpload = this.uploads[0] || null;
    }
    get serial() {
        return serial(this.at, this.data.Amount);
    }
};
__decorate([
    Prop()
], PrizeInput.prototype, "data", void 0);
__decorate([
    Prop()
], PrizeInput.prototype, "at", void 0);
__decorate([
    Prop()
], PrizeInput.prototype, "index", void 0);
__decorate([
    Prop()
], PrizeInput.prototype, "canDel", void 0);
__decorate([
    Watch("uploads", { deep: true })
], PrizeInput.prototype, "onUpload", null);
PrizeInput = __decorate([
    Component
], PrizeInput);
export default PrizeInput;
