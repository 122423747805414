
	import { Component, Prop, Vue } from "vue-property-decorator";

	import MallItemSummary from "./MallItemSummary.vue";
	import SupplyStatus from "./SupplyStatus.vue";

	import type { MallItemModel } from "@/models/owner/Mall";

	@Component({ components: { MallItemSummary, SupplyStatus } })
	export default class ItemCard extends Vue {
		@Prop() public data!: MallItemModel;
	}
